(function () {
    'use strict';

    angular.module('cloudxcmClientsApp')
        .controller('CloudIconCtrlv2', CloudIconCtrlv2);

    CloudIconCtrlv2.$inject = ['Auth', 'Accountsv2', 'SweetAlert', 'lodash', '$uibModal', '$timeout', 'CloudIconsv2', '$translate'];


    function CloudIconCtrlv2(Auth, Accountsv2, SweetAlert, lodash, $uibModal, $timeout, CloudIconsv2, $translate) {
        var self = this;
        self.init = true;
        self.config = {};
        self.colorRdy = true;
        self.iconRdy = true;


        self.$onInit =
            function () {
                self.centralStation = self.resolve.centralStation;
                self.fetchAccount();
            };


        self.initialFetch =
            function () {
                CloudIconsv2.getAll()
                    .then(handleIcons, handleErr);
                CloudIconsv2.getColors()
                    .then(handleColors, handleErr);
            };


        function handleIcons(data) {
            self.icons = data.data;
            self.config.icon = lodash.find(self.icons, {svgPath: self.account.cloudIcon.image.svgPath});
            self.iconRdy = false;

        }

        function handleColors(data) {
            self.colors = data.data;
            self.config.color = lodash.find(self.colors, {hexColor: self.account.cloudIcon.color.value});
            self.colorRdy = false;
        }


        self.fetchAccount =
            function () {
                Accountsv2.getDetail(self.centralStation.idCentralStation, self.resolve.idAccount)
                    .then(handleAccount, handleErr);
            };


        self.refreshIcon =
            function () {
                if (self.config.icon) {
                    self.account.cloudIcon.image = angular.copy(self.config.icon);
                }

                if (self.config.color) {
                    self.account.cloudIcon.color.value = self.config.color.hexColor;
                }
            };


        self.update =
            function () {
                Accountsv2.updateCloudIconConfig(
                    self.account.idCentralStation,
                    self.account.idCouch,
                    {
                        idCentralStationClientIcon: self.config.icon._id,
                        idCentralStationClientColor: self.config.color._id
                    }
                ).then(handleUpdateSuccess, handleUpdateErr)

            };


        function handleUpdateSuccess() {
            SweetAlert.swal($translate.instant('ATTENTION'), $translate.instant('CONFIGURATION_UPDATE_SUCCESS'), "success");
            self.cancel();

        }

        function handleUpdateErr() {
            SweetAlert.swal($translate.instant('ATTENTION'), $translate.instant('CONFIGURATION_UPDATE_ERROR'), "error");
            self.cancel();
        }


        function handleAccount(data) {

            self.init = false;
            var account = self.account = data.data;
            if (!account.cloudIcon) {
                account.cloudIcon = {
                    image: {
                        value: "https://nevulacsblobmetaqa.blob.core.windows.net/metacenter/undefined/8d270375-28f3-4b93-bda8-86255d190583",
                        svgPath: 'M 32.5614 243 L 103.2982 243 L 103.2982 159.9756 L 152.7018 159.9756 L 152.7018 243 L 228.4912 243 L 227.9298 117.4312 L 256 137.0878 L 256 113.5156 L 227.9298 93.859 L 227.9298 13 L 196.4912 13 L 196.4912 71.8463 L 128 23.8829 L 0 113.5156 L 0 137.0878 L 32.5614 114.2841 L 32.5614 243 Z',

                    },
                    color: {
                        value: '#000000'
                    }
                };
            }

            self.cloudIcon = account.cloudIcon;

            self.initialFetch();


        }


        function handleErr(err) {
            console.log(err);
        }


        self.cancel =
            function () {
                self.modalInstance.dismiss('cancel');
            }


    }

})();