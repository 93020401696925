/**
 * Created by xpectra on 1/9/2016.
 */

angular.module('cloudxcmClientsApp')

  .factory('InvoicingData', ['lodash','$q',
    function(lodash, $q){
      return {
        formatToCurrency: function (number, minDecimals, maxDecimals) {
          if (isNaN(number)) return NaN;
          minDecimals = typeof minDecimals !== 'undefined' ? minDecimals : 2;
          maxDecimals = typeof maxDecimals !== 'undefined' ? maxDecimals : 6;
          if (typeof number != 'number') {
            number = parseFloat(number);
          }
          var formattedNumber = '';
          if (number % 1 == 0) {
            formattedNumber = number.toFixed(minDecimals);
          } else {
            if (number.toString().split('.')[1].length > maxDecimals) {
              formattedNumber = number.toFixed(maxDecimals);
            } else {
              formattedNumber = number.toString();
            }
          }
          return formattedNumber;
        }
      }
    }]);


