'use strict';

angular.module('cloudxcmClientsApp')
    .factory('Ticketsv2', Ticketsv2);

Ticketsv2.$inject = ['$http', '$cookies'];


function Ticketsv2($http, $cookies) {
    var self = this;

    self.response = {
        getByCentralStation: getByCentralStation,
        getDetail: getDetail,
        getLog: getLog,
        addNote:addNote
    };

    return self.response;

    function getByCentralStation(cs, skip, limit) {
        var idClient = $cookies.get('idClient');
        var url = "/api2/central-station-clients/" + idClient + "/tickets?filters[idCentralStation]=" + cs + "&orderBy=-createdAt&populate=idAccount&populate=requests.idRequest"
            + "&include=idCouch"
            + "&include=createdAtLocal"
            + "&include=origin"
            + "&include=status"
            + "&include=createdAt"
            + "&include=requests"
            + "&include=idCentralStation"
            + "&include=account.cloudIcon.image.svgPath"
            + "&include=account.cloudIcon.color.value"
            + "&include=account.name"
            + "&include=code"
            + "&skip=" + skip + "&limit=" + limit;
        return $http.get(url);
    }

    function getLog(cs, ticket) {
        var idClient = $cookies.get('idClient');
        var url = '/api2/central-station-clients/' + idClient + '/central-stations/' + cs + '/tickets/' + ticket +
            "/?virtuals=ticketLog&include=log";
        return $http.get(url);
    }

    function addNote(cs, ticket, note) {
        var idClient = $cookies.get('idClient');
        note.responsibleType = "CLIENT";
        var url = '/api2/central-station-clients/' + idClient + '/central-stations/' + cs + '/tickets/' + ticket + '/comments';

        return $http.post(url, note);
    }


    function getDetail(cs, ticket) {
        var idClient = $cookies.get('idClient');
        var url = '/api2/central-station-clients/' + idClient + '/central-stations/' + cs + '/tickets/' + ticket +
            "/?populate=idAccount&populate=idCentralStation&populate=requests.idRequest&populate=idClient&populate=centralStation.companyInfo.location.idRegion&populate=idTechnician"
            + "&include=requests"
            + "&include=account.location.address"
            + "&include=account.location.reference"
            + "&include=technician.phones"
            + "&include=centralStation.cloudImage"
            + "&include=centralStation.companyInfo.name"
            + "&include=centralStation.companyInfo.shortName"
            + "&include=centralStation.companyInfo.location.region.formattedAddress"
            + "&include=centralStation.companyInfo.email"
            + "&include=centralStation.companyInfo.phone"
            + "&include=code"
            + "&include=status"
            + "&include=technician.name"
            + "&include=technician.email"
            + "&include=technician.cloudImage"
            + "&include=account.cloudIcon.image.svgPath"
            + "&include=account.cloudIcon.color.value"
            + "&include=account.name"
            + "&include=account.number"

        ;

        return $http.get(url);
    }


}