(function () {
    'use strict';

    angular.module('cloudxcmClientsApp')
        .controller('BillCtrlv2', BillCtrlv2);

    BillCtrlv2.$inject = ['Auth', 'Paymentsv2', 'SweetAlert', 'lodash', 'Accountsv2', '$scope', '$translate', '$route', '$uibModal'];

    function BillCtrlv2(Auth, Paymentsv2, SweetAlert, lodash, Accountsv2, $scope, $translate, $route, $uibModal) {
        var self = this;
        self.init = true;
        self.methods = [];

        self.$onInit =
            function () {
                self.subtotalConsume = 0;
                self.subtotalTax = 0;
                self.centralStation = self.resolve.centralStation;
                self.receipt = self.resolve.receipt;
                self.receipt.createdAt=moment.utc(self.receipt.createdAt).format("YYYY-MM-DD");
                lodash.forEach(self.receipt.proforma.concepts,
                    function (details) {

                        self.subtotalConsume = self.subtotalConsume + details.data.price;
                        lodash.forEach(
                            details.additional.taxes,
                            function (tax) {
                                self.subtotalTax = self.subtotalTax + tax.cost;
                            }
                        )
                    }
                )
            };


        self.cancel =
            function () {
                self.modalInstance.dismiss('cancel');
            };


        self.pay =
            function () {
                var modalInstance = $uibModal.open({
                    animation: true,
                    component: 'paymentMethod',
                    size: 'lg',
                    resolve: {
                        centralStation: function () {
                            return self.centralStation;
                        },
                        paymentData: function () {
                            return {
                                total:self.receipt.proforma.total,
                                receipts:[self.receipt.idCouch]
                            }
                        }
                    }
                });

            };


    }

})();