(function(){
    'use strict';

    var multipleBill = {
        bindings: {
            resolve:'<',
            modalInstance:'<'
        },
        templateUrl: 'app/invoicing2/bills/modal/multiple/multiple.bill.html',
        controller: 'MultipleBillCtrlv2 as vm'
    };

    angular
        .module('cloudxcmClientsApp')
        .component('multipleBill', multipleBill);

})();