(function(){
    'use strict';

    var account = {
        bindings: {
            resolve:'<',
            modalInstance:'<'
        },
        templateUrl: 'app/accounts2/modal/detail/account.detail.html',
        controller: 'AccountDetailCtrlv2 as vm'
    };

    angular
        .module('cloudxcmClientsApp')
        .component('account', account);

})();