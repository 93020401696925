'use strict';

angular.module('cloudxcmClientsApp')
    .config(config);

config.$inject = ['$routeProvider'];

function config($routeProvider) {
    $routeProvider
        .when('/support2', {
                templateUrl: 'app/support2/support.html',
                controller: 'SupportCtrlv2 as vm',
                label: 'SUPPORT_MANAGEMENT'
            }
        )
        .when('/tickets', {
                template:'<tickets></tickets>',
                label: 'SUPPORT_MANAGEMENT'
            }
        )
        .when('/requests', {
                template:'<requests></requests>',
                label: 'SUPPORT_MANAGEMENT'
            }
        )
    ;
}