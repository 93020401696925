'use strict';

angular.module('cloudxcmClientsApp')
  .controller('LoginCtrl',
      ['$scope', 'Auth', '$location', '$cookieStore', '$cookies','$window', '$rootScope',
      function ($scope, Auth, $location, $cookieStore, $cookies,$window, $rootScope) {

    if ($location.search().error === 'access_denied') {
      Auth.resetPassword();
    } else {
    if (!$cookieStore.get('token')) {
        if ($location.search().code) {
          Auth.login({
            authorizationCode: $location.search().code
          }).then(function (response) {
            Auth.getCurrentUser(function(data){
              console.log("estos son los datos de usuario");
              console.log(data);
              if(data.centralStationClient._id){
                $cookies.put('idClient', data.centralStationClient._id);
                console.log($cookies.get('idClient'));
                $location.url('/alarms');
                $rootScope.$broadcast('scanner-started', { isLoggedIn: true });
              }
              else
                Auth.logout();

            });

          });
        }
        else {
          if($cookieStore.get('refreshToken')){
            Auth.refreshLogin($cookieStore.get('refreshToken'));

          }else
          $window.location.href = 'https://login.microsoftonline.com/nevulaservicespr.onmicrosoft.com/oauth2/v2.0/authorize?client_id=b20e71d3-4be0-4281-b42a-fc6a8ea8bf66&redirect_uri=https%3A%2F%2Fclients.nevula.com&response_mode=query&response_type=code+id_token&scope=openid+offline_access&nonce=dummy&p=B2C_1_SIGN_UP_OR_SIGN_IN';
        }
      } else {

        Auth.getCurrentUser(function(data){
          console.log(data);
          if(data.centralStationClient._id){
            $cookies.put('idClient', data.centralStationClient._id);

            console.log($cookies.get('idClient'));
            $location.url('/alarms');
          }
          else
            Auth.logout();

        });

      }
    }
  }]);
