(function () {
    'use strict';

    angular.module('cloudxcmClientsApp')
        .controller('PaymentMethodsCtrlv2', PaymentMethodsCtrlv2);

    PaymentMethodsCtrlv2.$inject = ['Auth', 'Paymentsv2', 'SweetAlert', 'lodash', 'Accountsv2', '$scope', '$translate', '$route', '$uibModal'];

    function PaymentMethodsCtrlv2(Auth, Paymentsv2, SweetAlert, lodash, Accountsv2, $scope, $translate, $route, $uibModal) {
        var self = this;
        self.init = true;
        self.methods = [];

        self.$onInit =
            function () {
                self.centralStation = self.resolve.centralStation;
                self.fetchMethods();
            };

        function handleMethods(data) {
            self.methods = data.data;
            self.init = false;
        }


        self.fetchMethods =
            function () {
                Paymentsv2.getByCentralStation(self.centralStation.idCentralStation)
                    .then(handleMethods, handleErr);
            };


        function handleErr(err) {
            self.init = false;
            console.log(err);
        }

        self.cancel =
            function () {
                self.modalInstance.dismiss('cancel');
            };


        self.newPaymenMethod =
            function () {
                var modalInstance = $uibModal.open({
                    animation: true,
                    component: 'paymentMethod',
                    size: 'lg',
                    resolve: {
                        centralStation: function () {
                            return self.centralStation;
                        }
                    }
                });

            };


        self.deleteMethod =
            function (method) {
                SweetAlert.swal({
                    title: $translate.instant('SVAL_DELETE_PAYMENT_TYPE'),
                    text: $translate.instant('PERFORM_OPERATION_QUESTION'),
                    type: "warning",
                    confirmButtonColor: "#DD6B55",
                    cancelButtonText: $translate.instant('CANCEL'),
                    showCancelButton: true,
                    closeOnConfirm: false,
                    showLoaderOnConfirm: true
                }, function (isConfirm) {
                    if (isConfirm) {
                        Paymentsv2.deletePaymentMethod(self.centralStation.idCentralStation, method)
                            .then(
                                function success(resp) {
                                    SweetAlert.swal({
                                        title: $translate.instant('ATTENTION'),
                                        text: $translate.instant('DELETED_PAYMENT_TYPE'),
                                        type: "success",
                                        closeOnConfirm: true
                                    }, function () {
                                        self.init = true;
                                        self.fetchMethods();
                                    });
                                },
                                function error(err) {
                                    if (err.status === 409 || err.status === 408) {
                                        SweetAlert.swal($translate.instant('ATTENTION'), $translate.instant('SVAL_COMUNICATION_PAYU_ERROR'), "error");
                                    }
                                    console.log(err)
                                }
                            );

                    }
                });


            };
    }

})();