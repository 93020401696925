(function () {
    'use strict';
    angular.module('cloudxcmClientsApp')
        .factory('Alarmsv2', Alarmsv2)
        .config(config);

    config.$inject = ['$routeProvider'];
    Alarmsv2.$inject = ['$http', '$cookies'];


    function Alarmsv2($http, $cookies) {
        var self = this;

        self.response = {
            getByCentralStation: getByCentralStation,
            getDetail:getDetail
        };

        return self.response;

        function getByCentralStation(cs, skip, limit) {
            var idClient = $cookies.get('idClient');
            var url = "/api2/central-station-clients/" + idClient + "/alarms?filters[idCentralStation]=" + cs + "&populate=idAccount&virtuals=fullStateRule&orderBy=-createdAtLocal" +
                "&include=idCouch"+
                "&include=idCentralStation"+
                "&include=status"+
                "&include=clientName"+
                "&include=createdAtLocal"+
                "&include=stateRule.classification.key"+
                "&include=stateRule.classification.subClassification.key"+
                "&include=account.data.location.address"+
                "&include=account.data.cloudIcon.image.svgPath"+
                "&include=account.data.cloudIcon.color.value"+
                "&include=account.data.name"+
                "&include=account.data.location.address"+
                "&skip=" + skip + "&limit=" + limit;

            return $http.get(url);
        }

        function getDetail(cs, alarm){
            var idClient = $cookies.get('idClient');
            var url='/api2/central-station-clients/'+idClient+'/central-stations/'+cs+'/alarms/'+alarm+
                "/?populate=idAccount&populate=idCentralStation&populate=idClient&populate=centralStation.companyInfo.location.idRegion&virtuals=fullStateRule&orderBy=-createdAtLocal"
                +"&include=status"
                +"&include=idCouch"
                +"&include=icon"
                +"&include=account.data.cloudIcon.image.svgPath"
                +"&include=readed.user"
                +"&include=client.name"
                +"&include=account.data.name"
                +"&include=processed.latitude"
                +"&include=processed.longitude"
                +"&include=centralStation.cloudImage"
                +"&include=centralStation.companyInfo.name"
                +"&include=centralStation.companyInfo.shortName"
                +"&include=centralStation.companyInfo.location.region.formattedAddress"
                +"&include=centralStation.companyInfo.email"
                +"&include=centralStation.companyInfo.phone"
                +"&include=stateRule.classification.key"
                +"&include=stateRule.classification.subClassification.key";


            return $http.get(url);
        }



    }

    function config($routeProvider) {
        $routeProvider
            .when('/alarms', {
                templateUrl: 'app/alarms2/alarms.html',
                controller: 'AlarmsCtrlv2 as vm',
                label: 'ALARMS_MANAGEMENT'
            });
    }

})();



