(function () {
    'use strict';

    angular.module('cloudxcmClientsApp')
        .controller('TicketNoteCtrlv2', TicketNoteCtrlv2);

    TicketNoteCtrlv2.$inject = ['Auth', 'Ticketsv2', 'SweetAlert', 'lodash', '$uibModal', '$timeout', '$translate', 'DTOptionsBuilder', 'DTColumnBuilder', '$filter'];

    function TicketNoteCtrlv2(Auth, Ticketsv2, SweetAlert, lodash, $uibModal, $timeout, $translate, DTOptionsBuilder, DTColumnBuilder, $filter) {
        var self = this;
        self.Ticket = {};
        self.init = true;


        self.$onInit =
            function () {
                self.event = {};
                self.form = {};
                self.cs=self.resolve.idCentralStation;
                self.ticket=self.resolve.idTicket;
            };


        self.save =
            function () {

                Ticketsv2.addNote(self.cs, self.ticket, self.event)
                    .then(noteAdded, err)

            };


        function noteAdded(data) {

            self.bottomStatus = 2;
            swal($translate.instant('ATTENTION'), $translate.instant('TICKET_NOTE_SUCCESS'), "success");
            self.cancel();
        }


        function err(err) {

            swal($translate.instant('ATTENTION'), $translate.instant('ERROR'), "error");
            self.cancel();
        }


        self.cancel =
            function () {
                self.modalInstance.dismiss('cancel');
            }


    }

})();