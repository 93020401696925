'use strict';

angular.module('cloudxcmClientsApp')
    .controller('SidebarCtrl', ['$scope', '$location', 'Auth', 'lodash', '$cookies',
        function ($scope, $location, Auth, lodash, $cookies) {
            $.AdminLTE.layout.activate();
            Auth.getCurrentUser().then(function (data) {
                $cookies.put('idClient', data.centralStationClient._id);
                if (data.centralStationClient) {
                    $scope.appClient = 'client';


                    $scope.menu = [
                        {
                            nombre: "ALARMS_EVENTS",
                            icon: "icon icon-ico-cloud-alarmas-eventos",
                            link: "/alarms"

                        },
                        {
                            nombre: "ACCOUNTS",
                            icon: "icon icon-ico-cloud-cuenta-alt",
                            link: "/accounts"
                        },

                        {
                            nombre: "BILLS",
                            icon: "icon icon-ico-cloud-facturacion-pagos",
                            subniveles: [
                                {
                                    nombre: "PENDINGS",
                                    icon: "icon icon-ico-cloud-facturacion-pagos",
                                    link: "/bills"
                                },
                                {
                                    nombre: "PAIDS",
                                    icon: "icon icon-ico-cloud-facturacion",
                                    link: "/payments"
                                }

                            ]

                        },

                        {
                            nombre: "SUPPORT",
                            icon: "icon icon-ico-cloud-soporte-tecnico",
                            subniveles:[
                                {
                                    nombre: "REQUESTS",
                                    icon: "icon icon-ico-cloud-solicitud",
                                    link: "/requests"
                                },
                                {
                                    nombre: "MY_TICKETS",
                                    icon: "icon icon-ico-cloud-ticket",
                                    link: "/tickets"
                                }

                            ]

                        }

                    ];








                }
            })
        }])

    .directive('sidebarLoaded', ['$rootScope', '$timeout', function ($rootScope, $timeout) {
        return function (scope, element, attrs) {
            if (scope.$last) {
                $timeout(function () {
                    $.AdminLTE.tree('.sidebar');
                }, 100);
            }
        };
    }]);
