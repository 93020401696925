(function(){
    'use strict';

    var ticket = {
        bindings: {
            resolve:'<',
            modalInstance:'<'
        },
        templateUrl: 'app/support2/tickets/modal/detail/ticket.detail.html',
        controller: 'TicketDetailCtrlv2 as vm'
    };

    angular
        .module('cloudxcmClientsApp')
        .component('ticket', ticket);

})();