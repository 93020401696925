'use strict';

angular.module('cloudxcmClientsApp')
    .controller('BillsCtrlv2', BillsCtrlv2);

BillsCtrlv2.$inject = ['Auth', 'Billsv2', 'SweetAlert', 'lodash', '$uibModal', 'Accountsv2', '$rootScope'];

function BillsCtrlv2(Auth, Billsv2, SweetAlert, lodash, $uibModal, Accountsv2, $rootScope) {
    var self = this;
    $rootScope.titulo = "BILLS";
    $rootScope.subtitulo = "PENDINGS";
    self.centralStations = [];
    self.currentAcc = null;
    self.centralStationsRdy = false;
    self.Bills = [];
    self.query = true;
    self.updateInProgress = false;
    self.noMoreDataAvaliable = false;
    self.resultByPage = 10;
    self.currentPage = 0;
    self.accounts = [];
    self.queryAccounts = true;
    self.queryCs = true;
    self.defaultCheck = true;
    self.paymentQueue = {};
    self.totalBill = 0;
    self.totalTax = 0;


    self.handleBill =
        function (bill) {
            if (bill.checked) {
                self.totalBill += bill.total;
                self.totalTax += bill.totalTax;
                self.paymentQueue[bill.idCouch] = undefined;
            } else {
                self.totalBill -= bill.total;
                self.totalTax -= bill.totalTax;
                delete self.paymentQueue[bill.idCouch];
            }

        };


    self.checkAll =
        function () {
            lodash.forEach(self.Bills,
                function (bill) {
                    if (bill.checked !== self.defaultCheck) {
                        bill.checked = self.defaultCheck;
                        self.handleBill(bill);
                    }

                }
            );

        };


    function calculateTotals(bill) {
        var total = 0;
        var taxTotal = 0;

        lodash.forEach(bill.proforma.concepts,
            function (concept) {
                total += concept.data.price;
                lodash.forEach(concept.additional.taxes,
                    function (tax) {
                        taxTotal += tax.cost;
                    }
                )
            }
        );

        return {
            total: total,
            tax: taxTotal
        };
    }


    self.engagePayment =
        function (cs) {
            var modalInstance = $uibModal.open({
                animation: true,
                component: 'multipleBill',
                size: 'lg',
                resolve: {
                    centralStation: function () {
                        return cs
                    },
                    paymentData: function () {
                        return {
                            'receipts': lodash.filter(self.Bills, {"checked": true}),
                            'total': (self.totalBill + self.totalTax)
                        }
                    }

                }
            });

        };

    self.fetchAccounts =
        function (cs) {
            self.currentCs = cs;
            self.queryAccounts = true;
            Accountsv2
                .getMinimalBycentralStation(cs.idCentralStation)
                .then(handleAccounts, handleError);
        };


    function handleAccounts(data) {

        self.accounts = data.data;
        lodash.forEach(
            self.accounts,
            function (account) {
                account.cloudIcon = account.cloudIcon || {
                    image: {
                        value: "https://nevulacsblobmetaqa.blob.core.windows.net/metacenter/undefined/8d270375-28f3-4b93-bda8-86255d190583",
                        svgPath: 'M 32.5614 243 L 103.2982 243 L 103.2982 159.9756 L 152.7018 159.9756 L 152.7018 243 L 228.4912 243 L 227.9298 117.4312 L 256 137.0878 L 256 113.5156 L 227.9298 93.859 L 227.9298 13 L 196.4912 13 L 196.4912 71.8463 L 128 23.8829 L 0 113.5156 L 0 137.0878 L 32.5614 114.2841 L 32.5614 243 Z',

                    },
                    color: {
                        value: '#000000'
                    }
                };
            }
        );
        self.queryAccounts = false;

    }


    Auth.getCurrentUser()
        .then(
            handleUser,
            handleError
        );

    function handleUser(data) {
        self.centralStations = lodash.map(data.centralStationClient.centralStations, 'centralStation');
        self.centralStationsRdy = true;
        self.queryCs = false;
    }


    self.getColorByStatus =
        function (status) {
            switch (status) {
                case "PENDING":
                    return "#FF0000";
                case "ON_HOLD":
                    return "#FF0000";
                case "ON_ATTENTION":
                    return "#FFD700";
                case "ATTENDED":
                    return "#FF0000";
            }
        };


    self.getCurrentIcon =
        function (classification) {
            var local = lodash.find(self.iconAll, {classification: classification.key});
            if (local) {
                var icon = lodash.find(local.subClassification, {name: classification.subClassification ? classification.subClassification.key : 'OPENINGS_CLOSINGS'});
                if (icon)
                    return icon.icon;
                else
                    return 'icon icon-ico-ea-aperturas-cierres';
            }
        };


    self.calcDate =
        function (date) {

            var currDate = moment(date);
            var today = moment(new Date);
            var yesterday = moment(today.subtract(1, 'day'));
            var prefix = '';

            currDate = currDate.format("DD-MM-YYYY");
            today = today.format("DD-MM-YYYY");
            yesterday = yesterday.format("DD-MM-YYYY");

            if (currDate === today) {
                prefix = $translate.instant('TODAY');

            }
            else if (currDate === yesterday) {
                prefix = $translate.instant('YESTERDAY');

            }
            else {
                prefix = currDate;

            }

            return prefix;
        };

    self.transformBill =
        function (bill) {
            self.Bills.push(bill);
            self.paymentQueue[bill._id] = bill._id;
            bill.checked = true;
            var values = calculateTotals(bill);
            bill.total = values.total;
            bill.totalTax = values.tax;
            self.totalBill += values.total;
            self.totalTax += values.tax;
        };


    function handleBills(data) {
        lodash.forEach(data.data, self.transformBill);
        self.currentPage++;
        self.query = false;
        self.updateInProgress = false;
        if (data.data.length === 0)
            self.noMoreDataAvaliable = true;

    }

    function handleError(err) {

        self.query = false;
        self.queryCs = false;
        self.centralStationsRdy = true;
        self.queryAccounts = false;
        console.log(err);
    }


    self.refresh =
        function (cs) {
            self.currentAcc = null;
            self.fetchBills(self.currentAcc);

        };


    self.fetchBills =
        function (acc) {
            if (acc) {
                if (self.currentAcc !== acc) {
                    self.currentPage = 0;
                    self.currentAcc = acc;
                    self.paymentQueue = {};
                    self.Bills = [];
                    self.totalBill = 0;
                    self.totalTax = 0;
                    self.query = true;
                    self.updateInProgress = false;
                    self.noMoreDataAvaliable = false;

                }

                if (self.updateInProgress)
                    return;
                else
                    self.updateInProgress = true;


                Billsv2.getByCentralStation(self.currentCs.idCentralStation, acc.idCouch, self.resultByPage * self.currentPage, self.resultByPage)
                    .then(handleBills, handleError);
            }
        };


    self.payMultiples =
        function (cs) {
            var modalInstance = $uibModal.open({
                animation: true,
                component: 'multipleBill',
                size: 'md',
                resolve: {
                    centralStation: function () {
                        return cs
                    },
                    receipts: function () {
                        return lodash.filter(self.Bills, {checked: true});
                    },
                    total: function () {
                        return (self.totalBill + self.totalTax)
                    }

                }
            });
        };


    self.openBillsModal =
        function (centralStation, receipt) {
            var modalInstance = $uibModal.open({
                animation: true,
                component: 'bill',
                size: 'md',
                resolve: {
                    centralStation: function () {
                        return centralStation
                    },
                    receipt: function () {
                        return receipt;
                    }

                }
            });
        };


    self.OpenPaymentMethods =
        function (cs) {

            var modalInstance = $uibModal.open({
                    animation: true,
                    component: 'paymentMethods',
                    size: 'lg',
                    resolve: {
                        centralStation: function () {
                            return cs;
                        }
                    }
                }
            );

            modalInstance.result
                .then(function (result) {

                    },
                    function (err) {

                    })

        };


    self.iconAll = [
        {
            classification: 'EMERGENCIES',
            subClassification: [
                {
                    name: 'MEDICAL',
                    icon: 'icon icon-ico-ea-medico'
                },
                {
                    name: 'FIRE',
                    icon: 'icon icon-ico-ea-fuego'
                },
                {
                    name: 'FLOOD',
                    icon: 'icon icon-ico-ea-inundacion'
                },
                {
                    name: 'INTRUSION',
                    icon: 'icon icon-ico-ea-intrusion'
                },
                {
                    name: 'EMERGENCIES',
                    icon: 'icon icon-ico-ea-emergencia'
                },
                {
                    name: 'PANIC',
                    icon: 'icon icon-ico-ea-panico'
                },
                {
                    name: 'ASSAULT',
                    icon: 'icon icon-ico-ea-robo'
                }
            ]
        }, {
            classification: 'MOBILE_PANIC',
            subClassification: [
                {
                    name: 'MEDICAL',
                    icon: 'icon icon-ico-ea-panico-movil-medico-alt'
                },
                {
                    name: 'FIRE',
                    icon: 'icon icon-ico-ea-panico-movil-fuego-alt'
                },
                {
                    name: 'ASSAULT',
                    icon: 'icon icon-ico-ea-panico-movil-robo-alt'
                },
                {
                    name: 'ASSISTANCE',
                    icon: 'icon icon-ico-ea-panico-movil-asistencia-alt'
                }
            ]
        }, {
            classification: 'OPENINGS_CLOSINGS',
            subClassification: [
                {
                    name: 'OPENING',
                    icon: 'icon icon-ico-ea-apertura'
                },
                {
                    name: 'CLOSING',
                    icon: 'icon icon-ico-ea-cierre'
                },
                {
                    name: 'OPENINGS_CLOSINGS',
                    icon: 'icon icon-ico-ea-aperturas-cierres'
                },
                {
                    name: 'EARLY_OPENING',
                    icon: 'icon icon-ico-ea-cierre-temprano'
                },
                {
                    name: 'LATE_CLOSING',
                    icon: 'icon icon-ico-ea-cierre-tarde'
                },
                {
                    name: 'OPENING_OUT_TIME',
                    icon: 'icon icon-ico-ea-panico-movil-robo-alt'
                },
                {
                    name: 'CLOSING_OUT_TIME',
                    icon: 'icon icon-ico-ea-panico-movil-fuego-alt'
                },
                {
                    name: 'FAILED_OPENING',
                    icon: 'icon icon-ico-ea-panico-movil-robo-alt'
                },
                {
                    name: 'FAILED_CLOSING',
                    icon: 'icon icon-ico-ea-panico-movil-fuego-alt'
                },
                {
                    name: 'ABSENCE_OPENING',
                    icon: 'icon icon-ico-ea-apertura-ausencia'
                },
                {
                    name: 'ABSENCE_CLOSING',
                    icon: 'icon icon-ico-ea-cierre-ausencia'
                },
                {
                    name: 'REMOTE_OPENING',
                    icon: 'icon icon-ico-ea-panico-movil-robo-alt'
                },
                {
                    name: 'AUTO_ARM_FAILED',
                    icon: 'icon icon-ico-ea-panico-movil-asistencia-alt'
                },
                {
                    name: 'AUTOMATIC_OPENING',
                    icon: 'icon icon-ico-ea-panico-movil-robo-alt'
                },
                {
                    name: 'AUTOMATIC_CLOSING',
                    icon: 'icon icon-ico-ea-panico-movil-fuego-alt'
                }
            ]
        }, {
            classification: 'TEST',
            subClassification: [
                {
                    name: 'ABSENCE_REPORT',
                    icon: 'icon icon-ico-ea-panico-movil-medico-alt'
                },
                {
                    name: 'ZONE_TEST',
                    icon: 'icon icon-ico-ea-panico-movil-fuego-alt'
                },
                {
                    name: 'REPORT',
                    icon: 'icon icon-ico-ea-panico-movil-robo-alt'
                }
            ]
        }, {
            classification: 'FAULT',
            subClassification: [
                {
                    name: 'BATTERY_FAULT',
                    icon: 'icon icon-ico-ea-panico-movil-medico-alt'
                },
                {
                    name: 'ENERGY_FAULT',
                    icon: 'icon icon-ico-ea-panico-movil-fuego-alt'
                },
                {
                    name: 'ZONE_FAULT',
                    icon: 'icon icon-ico-ea-panico-movil-robo-alt'
                }
            ]
        }
    ];


}