'use strict';

angular.module('cloudxcmClientsApp')
  .directive('xpectracmCharts',
      ['$timeout', 'lodash', 'Indicator', '$q', '$modal',
      function ($timeout, lodash, Indicator, $q, $modal) {
    return {
      templateUrl: 'components/directives/xpectracm-charts/xpectracm-charts.html',
      restrict: 'EA',
      scope: {
        id: "@",
        title: "@",
        widgetscontent: "=",
        indicatordefaultconf: "=",
        rowHeight: "@",
        widgetSizeY:"@",
        widgetSizeX:"@",
        widgetType: "@",
        date: "="
      },
      link: function ($scope, element, attrs) {

        $scope.Prueba= Date.UTC('2015','1','13');
        $scope.Prueba2= moment('2015-10-13').unix();

       // console.log("1----------------", $scope.Prueba);
       // console.log("2----------------", $scope.Prueba2);

        $scope.noData=false;
        $scope.showloading=true;

        //SEGUN EL INTERVALO SELECCIONADO/CONFIGURADO LLEVA A FORMATO DE HIGHTCHARTS
        $scope.setChartTimeInterval= function(TimeInterval) {
          {
            switch (TimeInterval)
            {
              case "second":
                return ['%A, %b %e, %H:%M:%S', '%A, %b %e, %H:%M:%S', '-%H:%M:%S'];
                break;
              case "minute":
                return ['%A, %b %e, %H:%M', '%A, %b %e, %H:%M', '-%H:%M'];
                break;
              case "hour":
                return ['%A, %b %e, %H:%M', '%A, %b %e, %H:%M', '-%H:%M'];
                break;
              case "day":
                return ['%A, %b %e, %Y', '%A, %b %e', '-%A, %b %e, %Y'];
                break;
              case "week":
                return ['Week from %A, %b %e, %Y', '%A, %b %e', '-%A, %b %e, %Y'];
                break;
              case "month":
                return ['%B %Y', '%B', '-%B %Y'];
                break;
              case "year":
                return ['%Y', '%Y', '-%Y'];
                break;
            }
          }
        };

        $scope.idDiv=$scope.widgetscontent.id;
        $scope.indicatorCssClass="";

        //SI EL TIPO DE WIDGET ES PARA INDICADORES DE MEDIDA/SEMAFORO/ESCALAR
        $scope.generateMeasureChart=function() {

          var information=[];
          lodash.forEach($scope.widgetscontent.indicators, function(indicators) {

            indicators.config.dateRangeValue=angular.copy($scope.date[indicators.config.dateRange]);

            information.push({
              "startDate": indicators.config.dateRangeValue[0],
              "endDate": indicators.config.dateRangeValue[1],
              "timeInterval": $scope.widgetscontent.timeInterval,
              "idIndicator":indicators.indicator,
              "filterby":indicators.config.filterby,
              "filterValue":indicators.config.filterValue
            })
          });

          Indicator.getIndicatorSeries(information).then(
            function (response) {
              lodash.forEach(response, function(response,index) {
                $scope.widgetscontent.indicators[index].config.data=response.resultSerie;
                $scope.widgetscontent.indicators[index].config.dateRangeValue=[response.startDate,response.endDate];
              });

              //console.log("IMPRIMIR: ", response);
              $scope.loadMeasureChart();
            },
            function (err) {
              console.log("getIndicatorSeries ERROR: ",err.message," - ",err.description);
              return $q.reject(err);
            }
          );


          //JSON ENVIADO AL SERVER PARA OBTENER LA SERIE DE DATOS PARA LA GRAFICA


          $scope.loadMeasureChart= function() {

            $scope.showloading=false;
            var totalValue;

            //SE COLOCA LA CLASE CORRESPONDIENTE PARA MOSTRAR CORRECTAMENTE EL INDICADOR SEGUN EL ANCHO DEL WIDGET
            var tempcalc = 12 / $scope.widgetSizeX;

            lodash.forEach($scope.widgetscontent.indicators, function(indicators) {

              totalValue=indicators.config.data;

              indicators.config.indicatorCssClass = "col-xs-12  col-sm-" + tempcalc;

              //COLOR POR DEFECTO DEL INDICADOR DE MEDIDA/SEMAFORO/ESCALAR SI NO TIENE CONFIGURADO OBJETIVO
              indicators.config.indicatorCssColor="small-box bg-blue";
              indicators.config.indicatorImgBg="";

              //COMPARAMOS EL VALOR DEL INDICADOR CON EL DE LA OBJETIVO/TRAMA/TOLERANCIA/MARGEN CONFIGURADO - SE MUESTRA EL COLOR SEGUN EL OBJETIVO
              if(angular.isDefined(indicators.plotlines) && angular.isDefined(indicators.plotlines.value)) {
                if(totalValue>indicators.plotlines.value+indicators.plotlines.targetMargin) {
                  if(indicators.plotlines.targetIncrease) {
                    indicators.config.indicatorCssColor = "small-box bg-green";
                    indicators.config.indicatorImgBg = "fa fa-arrow-up";
                  }else {
                    indicators.config.indicatorCssColor="small-box bg-red";
                    indicators.config.indicatorImgBg="fa fa-arrow-down";
                  }
                }
                else if(totalValue<indicators.plotlines.value-indicators.plotlines.targetMargin){
                  if(indicators.plotlines.targetIncrease) {
                    indicators.config.indicatorCssColor="small-box bg-red";
                    indicators.config.indicatorImgBg="fa fa-arrow-down";
                  }else {
                    indicators.config.indicatorCssColor="small-box bg-green";
                    indicators.config.indicatorImgBg="fa fa-arrow-up";
                  }
                }
                else {
                  indicators.config.indicatorCssColor="small-box bg-yellow";
                }
              }

              //DATOS DE INDICADOR A MOSTRAR
              indicators.config.dataConfig = {
                id: indicators.id,
                name: indicators.config.name,
                dateRange: indicators.config.dateRange,
                value: totalValue
              };



            });
          };
          };


        //SI EL TIPO DE WIDGET ES PARA INDICADORES EN GRAFICA
        $scope.generateChart=function() {
        /*  $scope.chartConfig={
            loading: {
              hideDuration: 3000,
              showDuration: 3000
            },
            title: {
              text: undefined
            },
            size: {
              height: $scope.charHeight
            }
          };*/
          var information=[];
          lodash.forEach($scope.widgetscontent.indicators, function(indicators) {

            indicators.config.dateRangeValue=angular.copy($scope.date[indicators.config.dateRange]);

            $scope.starDateMenu=moment(indicators.config.dateRangeValue[0]); $scope.starDateMenu=$scope.starDateMenu.format("MMM DD, YYYY");
            $scope.endDateMenu=moment(indicators.config.dateRangeValue[1]); $scope.endDateMenu=$scope.endDateMenu.format("MMM DD, YYYY");

            //JSON ENVIADO AL SERVER PARA OBTENER LA SERIE DE DATOS PARA LA GRAFICA
            information.push({
              "startDate": indicators.config.dateRangeValue[0],
              "endDate": indicators.config.dateRangeValue[1],
              "timeInterval": $scope.widgetscontent.timeInterval,
              "idIndicator":indicators.indicator,
              "filterby": indicators.config.filterby,
              "filterValue": indicators.config.filterValue
            });
          });

          Indicator.getIndicatorSeries(information).then(
            function (response) {
              $scope.result=response;
              if(response.error){$scope.noData=true;}
              $scope.loadChart();
            },
            function (err) {
              console.log("getIndicatorSeries ERROR: ",err.message," - ",err.description);
              $scope.noData=true;
              return $q.reject(err);
            }
          );


         /* $scope.$watch($scope.getWindowDimensions, function(newValue, oldValue) {
            if (newValue !== oldValue) {
              $scope.loadChart();
            }
          }, true);

          console.log("resized.",element.width());
          element.on("resize", function () {
            console.log("resized.",element);
          });*/



          $scope.loadChart=function() {

            if($scope.noData) {
            }
            else
            {
              $scope.noData=false;

              $scope.seriesLoaded = [];
              lodash.forEach($scope.result, function(result, $index) {

                $scope.widgetscontent.indicators[$index].serie={};
                $scope.widgetscontent.indicators[$index].config.data=result.resultSerie;
                $scope.widgetscontent.indicators[$index].config.dateRangeValue=[result.startDate,result.endDate];

                $scope.seriesLoaded.push($scope.widgetscontent.indicators[$index].config);
              });

            var chartStarDate=$scope.widgetscontent.indicators[0].config.dateRangeValue[0].split("-");
            chartStarDate[1]=chartStarDate[1]-1; //HIGHTCHARTS TOMA ENERO COMO EL NUMERO "0" - CERO

            //GENERA LA GRAFICA A PARTIR DE LAS OPCIONES DE CHARTCONFIG
            $timeout(function () {
              $scope.showloading=false;
              $scope.chartConfig = {
                options: {
                 /* colors: ['#fe9776', '#37bc9b', '#4dd0e1', '#9575cd', '#98af67',
                    '#a87ea4', '#fc4128', '#416b6f', '#308bc2', '#59aaa1'],*/
                  chart: {
                    /*type: $scope.seriesLoaded.chartType,*/
                    /*height: 200,*/
                    zoomType: 'x'
                  },
                  plotOptions: {
                    series: {
                      showInLegend: showInLegend,
                     /* pointStart: Date.UTC(chartStarDate[0], chartStarDate[1], chartStarDate[2]),*/
                      pointInterval: 24 * 3600 * 1000 // one day
                    }
                  }
                }, /*
                 legend: {
                 enabled: legend
                 },*/
                size: {
                  height: $scope.charHeight
                },
                title: {
                  text: undefined
                },
                subtitle: {},
                credits: {
                  enabled: false
                },
                yAxis: {
                  title: {
                    text: yAxisTitle
                  },
                  labels: {
                    enabled: yAxislabels
                  }
                },
                func: function (chart) {
                  $timeout(function () {
                    chart.reflow();
                  })
                },
                xAxis: {
                  type: 'datetime',
                  dateTimeLabelFormats: { // don't display the dummy year
                    month: '%e. %b',
                    year: '%b'
                  },
                  title: {
                    text: undefined
                  },
                  //labels: {enabled:true,y : 20, rotation: -90, align: 'right' }
                  labels: {enabled: xAxislabels, align: 'right'}
                },
                series: $scope.seriesLoaded,
                exporting: false,
                loading: false
              };
            }, 0);
            }
          };

          //////////////////////////FUNCIONES DEL MENU://////////////////////////////////////////////

          //SELECCION DE RANGO DE FECHAS EN EL MENU
          $scope.selectDateRange= function(range) {
            lodash.forEach($scope.widgetscontent.indicators, function(indicators) {
              indicators.config.dateRange=range
            });
            $scope.generateChart();
          };

          $scope.showContent = function() {
            console.log("CHARTCONFIG CONTENIDO =========================: ",$scope.chartConfig);
          };
        };

        $scope.generateMiniChart=function() {

          showInLegend = false;
          legend = false;
          yAxisTitle = undefined;
          xAxislabels = false;
          yAxislabels = false;
          var tempcalc = 12 / $scope.widgetSizeX;
          $scope.indicatorCssClass = "col-xs-12 col-sm-" + tempcalc + " widgetEscalarItem";

          //GENERAMOS DATOS DE PRUEBA PARA LA SERIE DE LA GRAFICA
          var rnd = [];
          for (var i = 0; i < 10; i++) {
            rnd.push(Math.floor(Math.random() * 1000) + 1);
          }

          var serie={
            name: $scope.widgetscontent.indicators[0].name,
            data: rnd,
            type: $scope.widgetscontent.indicators[0].chartype
          };

          $scope.indicatorsList = [];

           /////////////////////////////////////////////////
           // Obtener Documento de Configuracion de Indicador
           //////////////////////////////////////////////////
          var id_configIndicator = "indicatorConf:"+$scope.widgetscontent.indicators[0].indicator;
          Indicator.getConfigIndicator(id_configIndicator).then(
            function (response) {
              $scope.indicatorsList.push(response);
              $scope.indicatorsList[0].series = serie;
              $scope.loadChart();
            },
            function (err) {
              console.log("error getConfigIndicator", err);
            }
          );

          $scope.loadChart=function(){
            //$scope.indicatorsList.push(lodash.findWhere($scope.indicatorsCharts, {'id':$scope.widgetscontent.indicators[0].indicator}));

            $scope.seriesLoaded = [];
            lodash.forEach($scope.indicatorsList, function (x) {
              $scope.seriesLoaded.push(x.series);
            });

            //GENERA LA GRAFICA A PARTIR DE LAS OPCIONES DE CHARTCONFIG
            $timeout(function () {

            $scope.chartConfig = {
              options: {
                chart: {
                  /*type: $scope.chartype,*/
                  /*height: 200,*/
                  zoomType: 'x',
                  plotBorderColor: '#DDDDDD',
                  plotBorderWidth: 1
                },
                plotOptions: {
                  series: {
                    showInLegend: showInLegend,
                    pointStart: Date.UTC(2015, 1, 1),
                    pointInterval: 24 * 3600 * 1000 // one day
                  }
                }
              }, /*
               legend: {
               enabled: legend
               },*/
              size: {
                height: $scope.charHeight
              },
              title: {
                text: undefined
              },
              subtitle: {},
              credits: {
                enabled: false
              },
              yAxis: {
                title: {
                  text: yAxisTitle
                },
                labels: {
                  enabled: yAxislabels
                }
              },
              func: function (chart) {
                $timeout(function () {
                  chart.reflow();
                })
              },
              xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: { // don't display the dummy year
                  month: '%e. %b',
                  year: '%b'
                },
             /*   title: {
                  text: undefined
                },
                //labels: {enabled:true,y : 20, rotation: -90, align: 'right' }*/
                labels: {enabled: false}
              },
              series: $scope.seriesLoaded,
              exporting: false,
              loading: false
            };

          }, 0);

          };

          //////////////////////////FUNCIONES DEL MENU://////////////////////////////////////////////d
          $scope.addSeries = function () {
            $scope.indicatorsChartsMenu.push(this.indicatorsCharts);
            $scope.chartConfig.series.push(this.indicatorsCharts.series);
            console.log("addSeries:",$scope.chartConfig);
          };


          //CAMBIA EL TIPO DE GRAFICA DEL INDICADOR SELECCIONADO
          $scope.swapChartType = function ($index,name) {
            $scope.indicatorsChartsMenu[$index].series.type=name;
            //$scope.chartConfig.series.type =this.charts;

            console.log("swapChartType:",$scope.indicatorsChartsMenu.type);
            console.log("\nchartConfig:", $scope.chartConfig);
            // $scope.chartConfig.options.chart.type =this.chartTypes.id;

            // this.chartTypes.class="disabled"//REVISAR
          };



          $scope.selectDateRange= function() {
            // $scope.chartConfig.options.chart
            // this.dateRanges.range
          };

          $scope.selectInterval= function() {
            // $scope.selectInterval.options.chart
            // this.dateRanges.range
          };

          $scope.addPoints = function () {
            var seriesArray = $scope.chartConfig.series;
            var rndIdx = Math.floor(Math.random() * seriesArray.length);
            seriesArray[rndIdx].data = seriesArray[rndIdx].data.concat([1, 10, 20])
          };


          $scope.removeRandomSeries = function () {
            var seriesArray = $scope.chartConfig.series;
            var rndIdx = Math.floor(Math.random() * seriesArray.length);
            seriesArray.splice(rndIdx, 1);
          };
        };


        $scope.generateStatistic = function() {
          $scope.regions= [
            {
              name: "Oriente"
            },
            {
              name: "Occidente"
            }
          ];

          $scope.clientTypes=[
            {name: "Residencial"},
            {name: "Comercial"},
            {name: "Coorporativo"}
          ];

          $scope.eventTypes=[
            {name: "Con Atención"},
            {name: "Automonitoreo"}
          ];

          $scope.categories=[
            {name: "Categoria 1"},
            {name: "Categoria 2"},
            {name: "Categoria 3"}
          ];

          $scope.ranges = [
            {name: "Últimas 8 Horas"},
            {name: "Ultimas 16 Horas"},
            {name: "Ultimas 24 Horas"},
            {name: "Ultimas 48 Horas"}
          ];

          var rnd = [];
          for (var i = 0; i < 8; i++) {
            rnd.push(Math.floor(Math.random() * 20) + 1);
          }

          $scope.seriesLoaded=[];

          $scope.seriesLoaded.push({
            data: rnd
          });

          //GENERA LA GRAFICA A PARTIR DE LAS OPCIONES DE CHARTCONFIG
          $timeout(function () {
            $scope.showloading=false;

            var showInLegend=true;
            var legend=true;
            var yAxisTitle=undefined;
            var xAxislabels=true;
            var yAxislabels=true;

            var chartStarDate=["2015","10","30"];

            $scope.chartConfig = {
              options: {
                chart: {
                  /*type: $scope.seriesLoaded.chartType,*/
                  /*height: 200,*/
                  zoomType: 'x',
                  plotBorderColor: '#DDDDDD',
                  plotBorderWidth: 1
                },
                plotOptions: {
                  series: {
                    showInLegend: showInLegend,
                    pointStart: 1446364800,
                    pointInterval: 3600 * 1000 // one hour
                  }
                }
              }, /*
               legend: {
               enabled: legend
               },*/
              size: {
                height: $scope.charHeight
              },
              title: {
                text: undefined
              },
              subtitle: {},
              credits: {
                enabled: false
              },
              yAxis: {
                title: {
                  text: yAxisTitle
                },
                labels: {
                  enabled: yAxislabels
                }
              },
              func: function (chart) {
                $timeout(function () {
                  chart.reflow();
                })
              },
              xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: { // don't display the dummy year
                  month: '%e. %b',
                  year: '%b'
                },
                title: {
                  text: undefined
                },
                //labels: {enabled:true,y : 20, rotation: -90, align: 'right' }
                labels: {enabled: xAxislabels, align: 'right'}
              },
              series: $scope.seriesLoaded,
              exporting: false,
              loading: false
            };
          }, 0);


        };

        $scope.indicatorCssClass="";
        $scope.charHeight=$scope.rowHeight*$scope.widgetSizeY-20;
        var showInLegend=true;
        var legend=true;
        var yAxisTitle=undefined;
        var xAxislabels=true;
        var yAxislabels=true;
        switch ($scope.widgetType)
        {
          case 'measure':
              //GENERAMOS EL INDICADOR
              $scope.generateMeasureChart();
          break;
          case 'chart':
            $scope.charHeight=$scope.rowHeight*$scope.widgetSizeY-80;
            $scope.generateChart();
          break;
          case 'minichart':
            $scope.charHeight=$scope.rowHeight*$scope.widgetSizeY-80;
            $scope.generateMiniChart();
          break;
          case 'statistic':
            $scope.charHeight=$scope.rowHeight*$scope.widgetSizeY-120;
            $scope.generateStatistic();
            break;
        }

        $scope.dateRanges = [
          ///////////////////////////////////////////////////////
          //COLOCAR INTERVALO COMPATIBLES DEPENDIENDO DEL RANGO
          ///////////////////////////////////////////////////////
          {"id": "LIVE", show:false, "range": "En vivo"},
          {"id": "TODAY", show:false, "range": "Hoy"},
          {"id": "YESTERDAY", show:false, "range": "Ayer"},
          {"id": "LAST_7_DAYS", show:true, "range": "Ultimos 7 Dias"},
          {"id": "LAST_MONTH", show:true, "range": "Ultimos mes"},
          {"id": "LAST_YEAR", show:true, "range": "Ultimos año"},
          {"id": "RANGE", show:false, "range": "Rango"}];

        $scope.timeInterval = [
          {"id": "minute", show:true, "interval": "Minuto"},
          {"id": "hour", show:true, "interval": "Hora"},
          {"id": "day", show:true, "interval": "Dia"},
          {"id": "week", show:true, "interval": "Semana"},
          {"id": "month", show:true, "interval": "Mes"},
          {"id": "year", show:true, "interval": "Año"}];

        /////////////////////////////////////////////////////
        // BORRAR HACIA ABAJO SI NO SE VA A USAR MAS
        /////////////////////////////////////////////////////
/*

        $scope.chartTypes = [
          {"id": "line", show:true, "name": "Line"},
          {"id": "spline", show:true, "name": "Smooth line"},
          {"id": "area", show:true, "name": "Area"},
          {"id": "areaspline", show:true, "name": "Smooth area"},
          {"id": "column", show:true, "name": "Column"},
          {"id": "bar", show:true, "name": "Bar"},
          {"id": "pie", show:true, "name": "Pie"},
          {"id": "scatter", show:true, "name": "Scatter"}];

        */

        $scope.openModal = function (size) {

          var modalInstance = $modal.open({
            animation: $scope.animationsEnabled,
            templateUrl: 'components/directives/xpectracm-charts/widget-configuration.html',
            controller: 'ModalInstanceCtrl',
            size: size,
            resolve: {
              widgetscontent: function () {
                return $scope.widgetscontent;
              },
              indicatordefaultconf: function () {
                return $scope.indicatordefaultconf;
              }
            }
          });

          modalInstance.result.then(function (widgetConf) {
            $scope.widgetscontent = widgetConf;
          }, function () {
            console.log('Modal dismissed at: ' + new Date());
          });

        }

      }
    };
  }])
  .controller('ModalInstanceCtrl', ['$scope', '$modalInstance', 'widgetscontent','indicatordefaultconf', 'lodash',
      function ($scope, $modalInstance, widgetscontent,indicatordefaultconf, lodash) {
    $scope.done=true;

    $scope.widgetscontent=widgetscontent;

    lodash.forEach($scope.widgetscontent.indicators, function(indicator) {
      indicator.defaultconf=lodash.findWhere(indicatordefaultconf, {'id':"indicatorConf:"+indicator.indicator});

      indicator.filterSettingMenu=lodash.findWhere(indicator.defaultconf.value.availableFilters, {'id': indicator.config.filterby});

      indicator.newconfig = {
        dateRange: indicator.config.dateRange,
        filterName: indicator.config.filterName,
        filterValue: indicator.config.filterValue,
        filterby: indicator.config.filterby,
        name: indicator.config.name,
        type: indicator.config.type
      };
    });

    var starDate = moment($scope.widgetscontent.indicators[0].config.dateRangeValue[0]);
    var endDate = moment($scope.widgetscontent.indicators[$scope.widgetscontent.indicators.length-1].config.dateRangeValue[1]);
    $scope.widgetscontent.dateRange = [starDate.format("MMM DD, YYYY"), endDate.format("MMM DD, YYYY")];

    $scope.filterType=function(filterid) {
      var filterType=filterid.split("_");
      return filterType[1];
    };

    $scope.filterPicked = function(value) {
    //  $scope.indicator[index].newconfigTemp=value;
      console.log(value);
    };

    $scope.quetipo=function(filterset) {
      return typeof filterset;
    };


    $scope.ok = function() {
      $modalInstance.close($scope.selected.item);
    };

    $scope.cancel = function() {
      $modalInstance.dismiss('cancel');
    };

    $scope.close = function() {
      $modalInstance.dismiss();
    };


    $scope.toggleMin = function() {
      $scope.minDate = $scope.minDate ? null : new Date();
    };
    $scope.toggleMin();
    $scope.maxDate = new Date(2020, 5, 22);

    $scope.openCalendar = function($event,calendar) {
      calendar.opened = true;
    };

    $scope.date1={}; $scope.date2={};

    $scope.date1.dateOptions = {
      formatYear: 'yy',
      startingDay: 1
    };

    $scope.date2.dateOptions = {
      formatYear: 'yy',
      startingDay: 1
    };

    $scope.formats = ['dd-MMMM-yyyy', 'MMM dd, yyyy', 'dd.MM.yyyy', 'shortDate'];
    $scope.format = $scope.formats[1];

    //console.log($scope.date1)

    $scope.date1 = {
      opened: false
    };
    $scope.date2 = {
      opened: false
    };

  }]);

/*
 //GENERAMOS DATOS DE PRUEBA PARA LA SERIE DE LA GRAFICA
 var rnd = [];
 for (var i = 0; i < 10; i++) {
 rnd.push(Math.floor(Math.random() * 1000) + 1);
 }*/
