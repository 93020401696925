(function () {
    'use strict';

    angular.module('cloudxcmClientsApp')
        .controller('MultipleBillCtrlv2', MultipleBillCtrlv2);

    MultipleBillCtrlv2.$inject = ['Auth', 'Paymentsv2', 'SweetAlert', 'lodash', 'Accountsv2', '$scope', '$translate', '$route', '$uibModal'];

    function MultipleBillCtrlv2(Auth, Paymentsv2, SweetAlert, lodash, Accountsv2, $scope, $translate, $route, $uibModal) {

        var self = this;
        self.init = true;
        self.methods = [];

        self.$onInit =
            function () {
                self.centralStation = self.resolve.centralStation;
                self.receipts = self.resolve.receipts;
                self.total=self.resolve.total
            };


        self.cancel =
            function () {
                self.modalInstance.dismiss('cancel');
            };


        self.pay =
            function () {
                var modalInstance = $uibModal.open({
                    animation: true,
                    component: 'paymentMethod',
                    size: 'lg',
                    resolve: {
                        centralStation: function () {
                            return self.centralStation;
                        },
                        paymentData: function () {
                            return {
                                total:self.total,
                                receipts:lodash.map(self.receipts,"_id")
                            }
                        }
                    }
                });

            };


    }

})();