(function(){
    'use strict';

    var bill = {
        bindings: {
            resolve:'<',
            modalInstance:'<'
        },
        templateUrl: 'app/invoicing2/bills/modal/detail/bill.html',
        controller: 'BillCtrlv2 as vm'
    };

    angular
        .module('cloudxcmClientsApp')
        .component('bill', bill);

})();