(function () {
    'use strict';

    angular.module('cloudxcmClientsApp')
        .factory('Paymentsv2', Paymentsv2)
        .config(config);

    Paymentsv2.$inject = ['$http', '$cookies', 'Upload'];


    function Paymentsv2($http, $cookies, Upload) {
        var self = this;

        self.response = {
            getByCentralStation: getByCentralStation,
            deletePaymentMethod: deletePaymentMethod,
            registerPayment: registerPayment,
            newPaymentMethod: newPaymentMethod
        };

        return self.response;

        function getByCentralStation(cs) {
            var idClient = $cookies.get('idClient');
            var url = "/api2/central-station-clients/" + idClient + "/central-stations/" + cs + "/payment-methods";
            return $http.get(url);
        }

        function deletePaymentMethod(cs, method) {
            var idClient = $cookies.get('idClient');
            var url = "/api2/central-station-clients/" + idClient + "/central-stations/" + cs + "/payment-methods/" + method;
            var req = {
                method: 'DELETE',
                url: url,
                headers: {
                    "Accept-Language": "es"
                }
            };
            return $http(req);
        }

        function registerPayment(cs, payment) {
            var idClient = $cookies.get('idClient');
            var url = "/api2/central-station-clients/" + idClient + "/central-stations/" + cs + "/payments/";
            var req = {
                method: 'POST',
                url: url,
                headers: {
                    "Accept-Language": "es"
                },
                data: payment
            };
            return $http(req);
        }

        function newPaymentMethod(cs, method) {
            var idClient = $cookies.get('idClient');
            var url = "/api2/central-station-clients/" + idClient + "/central-stations/" + cs + "/payment-methods/";
            var req = {
                method: 'POST',
                url: url,
                headers: {
                    "Accept-Language": "es"
                },
                data: method
            };
            return $http(req);
        }


    }


    config.$inject = ['$routeProvider'];

    function config($routeProvider) {
        $routeProvider
            .when('/payments', {
                templateUrl: 'app/payment2/payments.html',
                controller: 'PaymentsCtrlv2 as vm',
                label: 'SUPPORT_MANAGEMENT'
            });
    }


})();


