'use strict';

angular.module('cloudxcmClientsApp')
  .config(['$routeProvider',function ($routeProvider) {
    $routeProvider
      .when('/login', {
        templateUrl: 'app/account/login/login.html',
        controller: 'LoginCtrl'
      })
      .when('/signup', {
        templateUrl: 'app/account/signup/signup.html',
        controller: 'SignupCtrl'
      })
      .when('/settings', {
        templateUrl: 'app/account/settings/settings.html',
        controller: 'SettingsCtrl',
        authenticate: true
      });
  }])

.factory('AccountsOnly', ['$http', '$q', 'Upload', '$rootScope',
    function($http, $q, Upload, $rootScope){
      return {
        /**
         * Obtener todos las cuentas
         * @param callback
         * @returns {*}
         */
        getAll:function(callback){
          var cb = callback || angular.noop;
          var defer = $q.defer();
          $http.get('/api/accounts')
            .then(function (data) {
              defer.resolve(data.data);
              return cb(data);
            })
            .catch(function (err) {
              defer.reject(err);
              return cb(err);
            });

          return defer.promise;
        },
        getAccount: function (number, callback) {
          var cb = callback || angular.noop;
          var defer = $q.defer();
          $http.get('api/accounts?filter=NUMBER&number='+number)
            .then(function (data) {
              defer.resolve(data.data);
              return cb(data);
            })
            .catch(function (err) {
              defer.reject(err);
              return cb(err);
            });
          return defer.promise;
        },
        getWithoutClient: function (callback) {
          var cb = callback || angular.noop;
          var defer = $q.defer();
          $http.get('/api/accounts/')
            .then(function (data) {
              defer.resolve(data.data);
              return cb(data);
            })
            .catch(function (err) {
              defer.reject(err);
              return cb(err);
            });

          return defer.promise;
        },
        getWithClients: function (callback) {
          var cb = callback || angular.noop;
          var defer = $q.defer();
          $http.get('/api/accounts/withClient')
            .then(function (data) {
              defer.resolve(data.data);
              return cb(data);
            })
            .catch(function (err) {
              defer.reject(err);
              return cb(err);
            });

          return defer.promise;
        },
        /**
         * Guardar y actualizar lista de planos
         * @param Planes
         * @param Param
         * @param idClient
         * @param id
         * @param $scope
         * @param callback
         * @returns {*}
         */
        updatePlane: function (Planes, Param, idClient, id, $scope, callback) {
          var cb = callback || angular.noop;
          var defer = $q.defer();
          var files = [];
          var names = [];
          var j = 0;

          if (Param != 'delete') {
            for (var i = 0; i < Planes.length; i++) {
              if (typeof(Planes[i].img[0]) != 'string') {
                files[j] = Planes[i].img[0];
                names[j] = 'plane_' + Planes[i].id + '.jpg';
                Planes[i].img = names[j];
                j++;
              }
            }
            ;
          }

          Upload.upload({
            url: 'api/accounts/' + id + '/blueprints/' + Planes[0].id + "?action=" + Param,
            method: 'PUT',
            fields: {"planes": Planes[0]},
            file: files,
            fileName: names
          }).progress(function (evt) {
            var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            //console.log('progress: ' + progressPercentage + '% ' + evt.config.file.name);
            $scope.progress = progressPercentage;
          }).then(function (data) {
              //console.log(data);
              defer.resolve(data.data);
              return cb(data);
            })
            .catch(function (err) {
              defer.reject(err);
              return cb(err);
            });
          return defer.promise;
        },

        get: function (id, callback) {
          var cb = callback || angular.noop;
          var defer = $q.defer();

          $http.get('api/accounts/' + id)
            .then(function (data) {
              //console.log(data);
              defer.resolve(data.data);
              return cb(data);
            })
            .catch(function (err) {
              //console.log(err);
              defer.reject(err);
              return cb(err);
            });

          return defer.promise;
        },
        createPartition: function (idClient, idAccount, partition, callback) {
          var cb = callback || angular.noop;
          var defer = $q.defer();
          var files = [];
          var names = [];

          if (partition.image && partition.image[0].name) {
            files.push(partition.image[0]);
            var name_image = partition.image[0].name.split(".");
            partition.image = partition.id + "." + name_image[name_image.length - 1];
            names.push(partition.image);
          }
          ;

          //console.log(partition);
          //console.log(files);
          //console.log(names);

          Upload.upload({
              url: 'api/accounts/' + idAccount + '/partitions/' + partition.id,
              method: 'PUT',
              fields: partition,
              file: files,
              fileName: names
            })
            .then(function (data) {
              defer.resolve(data.data);
              return cb(data);
            })
            .catch(function (err) {
              defer.reject(err);
              return cb(err);
            });

          return defer.promise;
        },
        deletePartition: function (idClient, idAccount, partition, callback) {
          var cb = callback || angular.noop;
          var defer = $q.defer();
          if(idClient===undefined) idClient = $rootScope.idClient;
          $http.put('api/accounts/' + idAccount + '/partitions/' + partition.id + '?action=clean')
            .then(function (data) {
              defer.resolve(data.data);
              return cb(data);
            })
            .catch(function (err) {
              defer.reject(err);
              return cb(err);
            });

          return defer.promise;
        },
        createZone: function (idClient, idAccount, zone, callback) {
          var cb = callback || angular.noop;
          var defer = $q.defer();
          var files = [];
          var names = [];

          if (zone.image && zone.image[0].name) {
            files.push(zone.image[0]);
            var name_image = zone.image[0].name.split(".");
            zone.image = zone.zone + "." + name_image[name_image.length - 1];
            names.push(zone.image);
          }

          //console.log(zone);
          //console.log(files);
          //console.log(names);

          /*Upload.upload({
           url: 'api/accounts/' + idAccount + '/zones/' + zone.zone,
           method: 'PUT',
           fields: zone,
           file: files,
           fileName: names
           })
           .then(function (data) {
           defer.resolve(data.data);
           return cb(data);
           })
           .catch(function (err) {
           defer.reject(err);
           return cb(err);
           });*/

          return defer.promise;
        },
        deleteZone: function (idClient, idAccount, idZone, callback) {
          var cb = callback || angular.noop;
          var defer = $q.defer();
          if(idClient===undefined) idClient = $rootScope.idClient;
          $http.put('api/accounts/' + idAccount + '/zones/' + idZone.zone + '?action=clean')
            .then(function (data) {

              defer.resolve(data.data);
              return cb(data);
            })
            .catch(function (err) {
              defer.reject(err);
              return cb(err);
            });

          return defer.promise;
        },
        create: function (account, callback) {
          //console.log(id, account);
          var cb = callback || angular.noop;
          var defer = $q.defer();

          $http.post('api/accounts', account)
            .then(function (data) {

              defer.resolve(data.data);
              return cb(data);
            })
            .catch(function (err) {
              defer.reject(err);
              return cb(err);
            });

          return defer.promise;
        },
        updateAccount: function (id, idAccount, account, callback) {
          var cb = callback || angular.noop;
          var defer = $q.defer();

          $http.put('api/accounts/' + idAccount, account)
            .then(function (data) {
              defer.resolve(data.data);
              return cb(data);
            })
            .catch(function (err) {
              defer.reject(err);
              return cb(err);
            });

          return defer.promise;
        },
        delete: function (id, callback) {

          var cb = callback || angular.noop;
          var defer = $q.defer();

          $http.delete('api/accounts/' + id)
            .then(function (data) {
              defer.resolve(data.data);
              return cb(data);
            })
            .catch(function (err) {
              defer.reject(err);
              return cb(err);
            });

          return defer.promise;
        },
        validationNumber: function (idClient, id, number, rcvr, callback) {
          var cb = callback || angular.noop;
          var defer = $q.defer();
          if(idClient===undefined) idClient = $rootScope.idClient;
          $http.get('api/clients/' + idClient + '/accounts/receiver?number=' + number)
            .then(function (data) {
              defer.resolve(data.data);
              return cb(data);
            })
            .catch(function (err) {
              defer.reject(err);
              return cb(err);
            });

          return defer.promise;
        }
      };
    }])
