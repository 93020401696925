(function () {
    'use strict';

    angular.module('cloudxcmClientsApp')
        .controller('RequestDetailCtrlv2', RequestDetailCtrlv2);

    RequestDetailCtrlv2.$inject = ['Auth', 'Requestsv2', 'SweetAlert', 'lodash', 'Accountsv2', '$scope', '$translate', '$route'];

    function RequestDetailCtrlv2(Auth, Requestsv2, SweetAlert, lodash, Accountsv2, $scope, $translate, $route) {
        var self = this;
        self.request = {};
        self.accounts = [];
        self.init = true;


        self.dateParams = {
            hourStep: 1,
            minStep: 30,
            min: new Date(),
            max: new Date()
        };


        self.save =
            function () {
                $scope.$broadcast('show-errors-check-validity');
                self.requestValidation = true;
                self.request.preferredSchedule = self.preferredSchedule;
                self.request.origin = 'WEB';
                self.request.idAccount = self.account.idCouch;
                self.request.idCentralStation = self.account.centralStation.idCentralStation;


                var preRequest = {
                    request: angular.copy(self.request),
                    img: self.request.image
                };
                delete preRequest.request.image;


                Requestsv2.create(preRequest)
                    .then(
                        createSuccess,
                        createErr
                    )

            };

        function createSuccess(data) {
            self.statusView = 2;
            SweetAlert.swal($translate.instant('ATTENTION'), $translate.instant('REQUEST_ADD_SUCCESS'), "success");
            self.modalInstance.dismiss('cancel');
            $route.reload();

        }

        function createErr(err) {
            self.statusView = 3;
            SweetAlert.swal($translate.instant('ATTENTION'), $translate.instant('REQUEST_ADD_ERR'), "error");
            self.modalInstance.dismiss('cancel');
        }

        self.dateParams.min.setHours(7);
        self.dateParams.min.setMinutes(59);

        self.dateParams.max.setHours(18);
        self.dateParams.max.setMinutes(0);

        self.preferredSchedule = {
            monday: undefined,
            tuesday: undefined,
            wednesday: undefined,
            thursday: undefined,
            friday: undefined,
            saturday: undefined
        };

        self.days = {};


        self.updateShedule =
            function (key) {
                if (!!self.days[key]) {
                    self.preferredSchedule[key] = [{'close': self.timeFinal, 'open': self.timeInitial}];
                } else {
                    self.preferredSchedule[key] = undefined;
                    delete self.days[key]
                }

                self.selectedDays = Object.keys(self.days).length > 0;

            };


        self.timeInitial = moment().format();
        self.timeInitial = moment(self.timeInitial).hour(8);
        self.timeInitial.minutes(0);
        self.timeFinal = moment().format();
        self.timeFinal = moment(self.timeFinal).hour(18);
        self.timeFinal.minutes(0);


        Accountsv2.byUser()
            .then(
                handleAcccounts,
                handleErr
            );


        function handleAcccounts(data) {
            self.accounts = data.data;
            lodash.forEach(self.accounts,
                function (account) {
                    if (!account.cloudIcon) {
                        account.cloudIcon = {
                            image: {
                                value: "https://nevulacsblobmetaqa.blob.core.windows.net/metacenter/undefined/8d270375-28f3-4b93-bda8-86255d190583",
                                svgPath: 'M 32.5614 243 L 103.2982 243 L 103.2982 159.9756 L 152.7018 159.9756 L 152.7018 243 L 228.4912 243 L 227.9298 117.4312 L 256 137.0878 L 256 113.5156 L 227.9298 93.859 L 227.9298 13 L 196.4912 13 L 196.4912 71.8463 L 128 23.8829 L 0 113.5156 L 0 137.0878 L 32.5614 114.2841 L 32.5614 243 Z',

                            },
                            color: {
                                value: '#000000'
                            }
                        }
                    }
                }
            );
        }


        self.$onInit =
            function () {
                self.title = self.resolve.new ? 'NEW_REQUEST' : 'REQUEST_DETAILS';
                self.edit = self.resolve.edit;
                self.new = !self.edit;
                if (self.edit) {
                    self.fetchRequest();
                } else {
                    self.init = false;
                }
            };


        Auth.getCurrentUser()
            .then(
                handleUser,
                handleErr
            );

        function handleUser(data) {
            self.centralStations = lodash.map(data.centralStationClient.centralStations, 'centralStation');
        }


        self.fetchRequest =
            function () {
                Requestsv2.getDetail(self.resolve.idCentralStation, self.resolve.idRequest)
                    .then(handleRequest, handleErr);
            };


        function handleRequest(data) {
            self.request = data.data;
            self.init = false;
            self.account = self.request.account;
            self.account.centralStation = self.request.centralStation;

            lodash.forOwn(self.preferredSchedule,
                function (val, key) {
                    self.days[key] = !!self.request.preferredSchedule && !!self.request.preferredSchedule[key]
                }
            );

            self.selectedDays = !!self.request.preferredSchedule && Object.keys(self.request.preferredSchedule).length > 0;


        }

        function handleErr(err) {

            console.log(err);
        }

        self.cancel =
            function () {
                self.modalInstance.dismiss('cancel');
            }
    }

})();