(function () {
    'use strict';

    angular.module('cloudxcmClientsApp')
        .controller('TicketDetailCtrlv2', TicketDetailCtrlv2);

    TicketDetailCtrlv2.$inject = ['Auth', 'Ticketsv2', 'SweetAlert', 'lodash', '$uibModal', '$timeout'];


    function TicketDetailCtrlv2(Auth, Ticketsv2, SweetAlert, lodash, $uibModal, $timeout) {
        var self = this;
        self.Ticket = {};
        self.init = true;
        self.geocoder = new google.maps.Geocoder();


        self.$onInit =
            function () {
                self.fetchTicket();
            };


        self.fetchTicket =
            function () {
                Ticketsv2.getDetail(self.resolve.idCentralStation, self.resolve.idTicket)
                    .then(handleTicket, handleErr);
            };


        function handleTicket(data) {

            self.ticket = data.data;
            self.init = false;

            lodash.forEach(self.ticket.requests,
                function (request, index) {
                    if (request.request.cloudImage)
                        self.requestCloudImage = request.request.cloudImage;
                    if (index > 0) {
                        self.requestIncidence += ' / ' + request.request.incidence;
                        self.requestCode += ' / ' + request.request.code;
                    } else {
                        self.requestIncidence = request.request.incidence;
                        self.requestCode = request.request.code;
                    }
                }
            );

            self.accountAddress = self.ticket.account.location.address;
            if (!lodash.isEmpty(self.ticket.account.location.reference))
                self.accountAddress += '- ' + self.ticket.account.location.reference;

            if (!(lodash.isUndefined(self.ticket.technician)) && !(lodash.isUndefined(self.ticket.technician.phones))) {
                lodash.forEach(self.ticket.technician.phones,
                    function (phone, index) {
                        if (index > 0)
                            self.phoneTechnician += '/ ' + phone;
                        else
                            self.phoneTechnician = phone;
                    }
                );
            }
        }


        function handleErr(err) {

            console.log(err);
        }


        self.cancel =
            function () {
                self.modalInstance.dismiss('cancel');
            }


    }

})();