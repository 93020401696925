(function () {
    'use strict';

    angular.module('cloudxcmClientsApp')
        .controller('AlarmDetailCtrlv2', AlarmDetailCtrlv2);

    AlarmDetailCtrlv2.$inject = ['Auth', 'Alarmsv2', 'SweetAlert', 'lodash', '$uibModal', '$timeout'];


    function AlarmDetailCtrlv2(Auth, Alarmsv2, SweetAlert, lodash, $uibModal, $timeout) {
        var self = this;
        self.alarm = {};
        self.init = true;
        self.geocoder = new google.maps.Geocoder();


        self.$onInit =
            function () {
                self.fetchAlarm();
            };


        self.fetchAlarm =
            function () {
                Alarmsv2.getDetail(self.resolve.idCentralStation, self.resolve.idAlarm, self.resultByPage * self.currentPage, self.resultByPage)
                    .then(handleAlarm, handleErr);
            };


        function handleAlarm(data) {


            self.init = false;
            self.alarm = data.data;

            if (!self.alarm.account.data.cloudIcon) {
                self.alarm.account.data.cloudIcon = {
                    image: {
                        svgPath: "M 32.5614 243 L 103.2982 243 L 103.2982 159.9756 L 152.7018 159.9756 L 152.7018 243 L 228.4912 243 L 227.9298 117.4312 L 256 137.0878 L 256 113.5156 L 227.9298 93.859 L 227.9298 13 L 196.4912 13 L 196.4912 71.8463 L 128 23.8829 L 0 113.5156 L 0 137.0878 L 32.5614 114.2841 L 32.5614 243 Z"
                    }
                }
            }
            if (!self.alarm.account.data.cloudIcon) {
                self.alarm.account.data.cloudIcon = {
                    image: {
                        svgPath: "M 32.5614 243 L 103.2982 243 L 103.2982 159.9756 L 152.7018 159.9756 L 152.7018 243 L 228.4912 243 L 227.9298 117.4312 L 256 137.0878 L 256 113.5156 L 227.9298 93.859 L 227.9298 13 L 196.4912 13 L 196.4912 71.8463 L 128 23.8829 L 0 113.5156 L 0 137.0878 L 32.5614 114.2841 L 32.5614 243 Z"
                    }
                }
            }
            if (!self.alarm.account.data.cloudIcon.image.svgPath) {
                self.alarm.account.data.cloudIcon.image.svgPath = "M 32.5614 243 L 103.2982 243 L 103.2982 159.9756 L 152.7018 159.9756 L 152.7018 243 L 228.4912 243 L 227.9298 117.4312 L 256 137.0878 L 256 113.5156 L 227.9298 93.859 L 227.9298 13 L 196.4912 13 L 196.4912 71.8463 L 128 23.8829 L 0 113.5156 L 0 137.0878 L 32.5614 114.2841 L 32.5614 243 Z"
            }

            //ASIGNAR EL COLOR DEL ICONO DE LA ALARMA
            if (self.alarm.status === "PENDING") {
                self.alarm.color = "#FF0000";
            }
            if (self.alarm.status === "ON_HOLD") {
                self.alarm.color = "#FF0000";
            }
            if (self.alarm.status === "ON_ATTENTION") {
                self.alarm.color = "#FFD700";
            }
            if (self.alarm.status === "ATTENDED") {
                self.alarm.color = "#7F7F7F";
            }

            if (!self.alarm.readed || !self.alarm.readed.user) {
                self.userAlarm = self.alarm.client.name;
            }
            else {
                self.userAlarm = self.alarm.readed.user;
            }

            self.positions = [
                {
                    latitude: self.alarm.processed.latitude,
                    longitude: self.alarm.processed.longitude
                }
            ];

            self.geocoder.geocode(
                {
                    'location': new google.maps.LatLng
                    (
                        self.alarm.processed.latitude,
                        self.alarm.processed.longitude
                    )
                },
                handleMapResults
            );
        }

        function handleMapResults(results, status) {
            if (status === "OK") {
                if (results.length > 0) {
                    $timeout(function () {
                        self.direccionPanico = results[0].formatted_address;
                    }, 100);
                } else {
                    $q.resolve("no results found");
                }
            } else {
                $q.resolve("no results found");
            }

        }


        function handleErr(err) {
            console.log(err);
        }


        self.cancel =
            function () {
                self.modalInstance.dismiss('cancel');
            }


    }

})();