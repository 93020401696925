'use strict';

var tickets = {
    bindings: {},
    templateUrl: 'app/support2/tickets/tickets.html',
    controller: 'TicketsCtrlv2 as vm'
};

angular
    .module('cloudxcmClientsApp')
    .component('tickets', tickets);