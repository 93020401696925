angular.module('cloudxcmClientsApp')
  .factory('utils',
      ['lodash', 'Dealers','Equipment', 'EquipmentCategory', 'StateRules', 'Job', 'Technicians', 'Receptors',
          'ServiceTypesSupport', 'Users', 'TaxTypes', 'MeasureUnit', 'ConsumableType', 'Tools', 'EquipmentType',
          'Actions', 'AccountType', 'DeviceType', 'Holiday', 'Installers',
      function(lodash, Dealers,Equipment, EquipmentCategory, StateRules, Job, Technicians, Receptors,
                             ServiceTypesSupport, Users, TaxTypes, MeasureUnit, ConsumableType, Tools, EquipmentType,
                             Actions, AccountType, DeviceType, Holiday, Installers) {

    return {
      validateField : function (apiFunctionOpt, model, callback){

        var apiFunction;
        var status=0; // 0:normal |||||  1:loading/verifying   ||||||  2:validated/no-errors  |||||||  3:replicated/ERROR   ||||||  4:Other Api Error

        switch (apiFunctionOpt) {
          case "DealersEmail":
            apiFunction=Dealers.isValidEmail;
            break;
          case "DealersCode":
            apiFunction=Dealers.isValidCode;
            break;
          case "Equipment":
            apiFunction=Equipment.isValid;
            break;
          case "EquipmentCategory":
            apiFunction=EquipmentCategory.isValid;
            break;
          case "StateRules":
            apiFunction=StateRules.exists;
            break;
          case "ConsumableType":
            apiFunction=ConsumableType.isValid;
            break;
          case "Job":
            apiFunction=Job.isValid;
            break;
          case "ServiceTypesSupport":
            apiFunction=ServiceTypesSupport.isValid;
            break;
          case "Tools":
            apiFunction=Tools.isValid;
            break;
          case "Technicians":
            apiFunction=Technicians.isValid;
            break;
          case "ReceptorsCom":
            apiFunction=Receptors.isValidCom; // MODIFICAR GUIULIANA
            break;
          case "ReceptorsHostPort":
            apiFunction=Receptors.isValidHostPort; // MODIFICAR GUIULIANA
            break;
          case "Users":
            apiFunction=Users.isValidUsername;
            break;
          case "UsersEmail":
            apiFunction=Users.isValidCheckEmail;
            break;
          case "TaxType":
            apiFunction=TaxTypes.isValid;
            break;
          case "measureName":
            apiFunction=MeasureUnit.isValidName;
            break;
          case "measureUnit":
            apiFunction=MeasureUnit.isValidUnit;
            break;
          case "consumableType":
            apiFunction=ConsumableType.isValid;
            break;
          case "Tool":
            apiFunction=Tools.isValid;
            break;
          case "equipmentTypemodel":
            apiFunction=EquipmentType.isValid;
            break;
          case "actionName":
            apiFunction=Actions.isValid;
            break;
          case "AccountType":
            apiFunction=AccountType.isValid;
            break;
          case "DeviceType":
            apiFunction=DeviceType.isValid;
            break;
          case "holidayName":
            apiFunction=Holiday.isValid;
            break;
          case "emailInstaller":
            apiFunction=Installers.isValid;
            break;

        }
        if(model === "") {
          status=3;
          return callback({value:status});
        }else{
          if (model){
            apiFunction(model).then(
              function done(response){
                if (lodash.isObject(response) && lodash.has(response, 'exist')) {
                  if (lodash.isString(response.exist)) response = response.exist == 'true';
                  else if (lodash.isBoolean(response.exist)) response = response.exist;
                }

                if (response === true ){
                  status=3;
                  return callback({value:status});
                }else {
                  status=2;
                  return callback({value:status});
                }
              }, function fail(error){
                status=4;
                return callback({value:status});
              });
          } else {
            status=3;
            return callback({value:status});
          }


        }
        //return didThisFail ? { value: status} : realResult;

      },

      //FUNCION PARA VALIDAR TODOS LOS CAMPOS REQUERIDOS DEL FORMULARIO ANTES DE SER ENVIADO
      submitForm : function (actualForm, callback) {
        actualForm.$setSubmitted();

        //console.log(actualForm);

        if(!actualForm.$pending) {

          //console.log("ENTRO EN PENDING");
          var haserrors=false;
          var elem=$('.ng-invalid').closest(".form-group");
          if(!lodash.isEmpty(actualForm.$error))  {
            haserrors=true;
          }

          if(haserrors) {
            elem.addClass("has-error");
            //if(!elem.hasClass("has-error")) {elem.addClass( "has-error" ); }
            //console.log("REGRESO NADA");
            return false;
          }
          else {
            //console.log("REGRESO CALLBACK");
            return callback();
          }
        }
        else {
          //console.log("FORMULARIO EN EJECUCION");
        }
      }
    }
  }]);
