/**
 * Created by Ramiro on 21/3/2016.
 */
'use strict';

angular.module('cloudxcmClientsApp')
  .factory('DTBehaviour', ['$http', '$q','lodash',function ($http, $q, lodash) {
    var library = {
      fnStandingRedraw : function(oSettings) {
        if(oSettings.oFeatures.bServerSide === false){
          var before = oSettings._iDisplayStart;
          oSettings.oApi._fnReDraw(oSettings);
          oSettings._iDisplayStart = before;
          oSettings.oApi._fnCalculateEnd(oSettings);
        }
        oSettings.oApi._fnDraw(oSettings);
      },
      events: {
        preXhr: function (scope, settings) {
          if (!lodash.isUndefined(scope.pendingRequest))
            if (lodash.has(scope.pendingRequest, 'abort'))
              scope.pendingRequest.abort();
          scope.pendingRequest = settings.jqXHR;
        },
        init: function (scope, indexer, settings) {
          $(settings.oInstance.context.rows[1]).find('input').each(function () {
            var idx = lodash.indexOf($(settings.oInstance.context.rows[1]).find('input'), this);
            var obj = lodash.find(indexer, {type: 'input', selectorIndex: idx});
            if (lodash.has(obj, 'ngModel')) $(this).val(lodash.get(scope, obj.ngModel));
          });
          $(settings.oInstance.context.rows[1]).find('select').each(function () {
            var idx = lodash.indexOf($(settings.oInstance.context.rows[1]).find('select'), this);
            var obj = lodash.find(indexer, {type: 'select', selectorIndex: idx});
            if (lodash.has(obj, 'ngModel')) $(this).val(lodash.get(scope, obj.ngModel));
          });
        },
        stateLoaded: function (scope, indexer, json) {
          lodash.forEach(json.columns, function (row) {
            var idx = lodash.indexOf(json.columns, row);
            var obj = lodash.find(indexer, {columnIndex: idx});
            if (lodash.has(obj, 'ngModel')) lodash.set(scope, obj.ngModel, row.search.search)
          })
        }
      }
    };
    library.instanceCallback = function(scope, dtInstance, indexer){
      scope.dtInstance = dtInstance;

      scope.dtInstance.dataTable.oApi.fnStandingRedraw = library.fnStandingRedraw;
      scope.dtInstance.DataTable.on('preXhr.dt', function(e, settings, data){
        //console.log('preXhr.dt from service');
        library.events.preXhr(scope, settings);
      });
      scope.dtInstance.DataTable.on('init.dt', function(e, settings, json){
        //console.log('init.dt  from service');
        library.events.init(scope, indexer, settings);
      });
      scope.dtInstance.DataTable.on('stateLoaded.dt', function(e, settings, json){
        //console.log('stateLoaded.dt  from service');
        library.events.stateLoaded(scope, indexer, json);
      });
    };

    library.stateLoadedCallback = function(scope, indexer, json){
        library.events.stateLoaded(scope, indexer, json);
    };
    return library;
  }]);
