(function(){
    'use strict';

    var request = {
        bindings: {
            resolve:'<',
            modalInstance:'<'
        },
        templateUrl: 'app/support2/requests/modal/detail/request.detail.html',
        controller: 'RequestDetailCtrlv2 as vm'
    };

    angular
        .module('cloudxcmClientsApp')
        .component('request', request);

})();