'use strict';

angular.module('cloudxcmClientsApp')
    .controller('RequestsCtrlv2', RequestsCtrlv2);

RequestsCtrlv2.$inject = ['Requestsv2', 'Auth', 'lodash', '$uibModal', '$translate', '$rootScope','SweetAlert'];


function RequestsCtrlv2(Requestsv2, Auth, lodash, $uibModal, $translate, $rootScope,SweetAlert) {
    var self = this;
    $rootScope.titulo = "SUPPORT";
    $rootScope.subtitulo = "REQUESTS";
    self.centralStations = [];
    self.currentCs = null;
    self.centralStationsRdy = false;
    self.requests = [];
    self.query = true;
    self.updateInProgress = false;
    self.noMoreDataAvaliable = false;
    self.resultByPage = 10;
    self.currentPage = 0;


    Auth.getCurrentUser()
        .then(
            handleUser,
            handleError
        );


    function handleUser(data) {
        self.centralStations = lodash.map(data.centralStationClient.centralStations, 'centralStation');
        self.centralStationsRdy = false;
    }


    function handleError(err) {
        self.query = false;
        self.centralStationsRdy = false;
        console.log(err);
    }


    self.refresh =
        function (cs) {
            self.currentCs = null;
            self.fetchRequests(cs);

        };

    self.transformRequest =
        function (request) {

            var horaRequest = moment.utc(request.createdAtLocal);
            var classificationIcon = "";
            var icon = "";


            if (!request.account.cloudIcon) {
                request.account.cloudIcon =
                    {
                        image: {
                            value: "https://nevulacsblobmetaqa.blob.core.windows.net/metacenter/undefined/8d270375-28f3-4b93-bda8-86255d190583",
                            svgPath: 'M 32.5614 243 L 103.2982 243 L 103.2982 159.9756 L 152.7018 159.9756 L 152.7018 243 L 228.4912 243 L 227.9298 117.4312 L 256 137.0878 L 256 113.5156 L 227.9298 93.859 L 227.9298 13 L 196.4912 13 L 196.4912 71.8463 L 128 23.8829 L 0 113.5156 L 0 137.0878 L 32.5614 114.2841 L 32.5614 243 Z',

                        },
                        color: {
                            value: '#000000'
                        }
                    }
            }

            if (request.origin) {
                request.origin = $translate.instant('CREATED_BY') + $translate.instant(request.origin);
            }

            if (request.status === 'SENT') {
                request.iconStatus = 'icon icon-ico-cloud-solicitud';
            } else if (request.status === 'ASSIGNED' || request.status === 'OPENED') {
                request.iconStatus = 'icon icon-ico-cloud-ticket-planificado';
            } else if (request.status === 'CANCELED') {
                request.iconStatus = 'icon icon-ico-cloud-solicitud-cancelada';
            } else if (request.status === 'FINISHED' || request.status === 'CLOSED') {
                request.iconStatus = 'icon icon-ico-cloud-ticket-finalizado';
            }


            request.createdAt = self.calcDate(request.createdAt) + ' ' + moment.utc(request.createdAt).format("HH:mm:ss");
            self.requests.push(request);
        };

    self.delete =
        function (request) {

            SweetAlert.swal({
                    title: $translate.instant('ARE_YOU_SURE'),
                    text: $translate.instant('DIALOG_CLOUD_DELETE_CONFIRM'),
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: $translate.instant('YES_SURE'),
                    cancelButtonText: $translate.instant('CANCEL'),
                    closeOnConfirm: false,
                    closeOnCancel: true
                },
                function (confirm) {
                    if (confirm) {
                        Requestsv2.Delete(self.currentCs.idCentralStation, request.idCouch)
                            .then(
                                function (resp) {
                                    SweetAlert.swal($translate.instant("ATTENTION"), $translate.instant("DIALOG_CLOUD_DELETE_SUCCESS"), "success");
                                    self.refresh(this.currentCs);
                                },
                                function (err) {
                                    SweetAlert.swal($translate.instant("ATTENTION"), $translate.instant("DIALOG_CLOUD_DELETE_ERROR"), "error");
                                }
                            );
                    }
                }
            );


        };

    self.calcDate =
        function (date) {
            console.log(date);


            var currDate = moment.utc(date);
            var today = moment();
            var yesterday = moment().subtract(1, 'day');
            var prefix = '';

            currDate = currDate.format("DD-MM-YYYY");
            today = today.format("DD-MM-YYYY");
            yesterday = yesterday.format("DD-MM-YYYY");

            if (currDate === today) {
                prefix = $translate.instant('TODAY');

            }
            else if (currDate === yesterday) {
                prefix = $translate.instant('YESTERDAY');

            }
            else {
                prefix = currDate;

            }
            console.log('es ' + prefix);
            return prefix;

        };

    function handleRequests(data) {
        lodash.forEach(data.data, self.transformRequest);
        self.currentPage++;
        self.query = false;
        self.updateInProgress = false;
        if (data.data.length === 0)
            self.noMoreDataAvaliable = true;

    }


    self.fetchRequests =
        function (cs) {
            if (cs) {
                if (self.currentCs !== cs) {
                    self.currentPage = 0;
                    self.currentCs = cs;
                    self.requests = [];
                    self.query = true;
                    self.updateInProgress = false;
                    self.noMoreDataAvaliable = false;
                }

                if (self.updateInProgress)
                    return;
                else
                    self.updateInProgress = true;


                Requestsv2
                    .getByCentralStation(cs.idCentralStation, self.resultByPage * self.currentPage, self.resultByPage)
                    .then(handleRequests, handleError);
            }
        };


    self.openRequestModal =
        function (centralStation, id) {
            var modalInstance = $uibModal.open({
                animation: true,
                component: 'request',
                size: 'md',
                resolve: {
                    idCentralStation: function () {
                        return centralStation
                    },
                    edit: function () {
                        return true;
                    },
                    idRequest: function () {
                        return id;
                    }
                }
            });

            /*var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/support/clients/modal-request.html',
                controller: 'ModalRequestClientsCtrl',
                size: 'md',
                resolve: {
                    items: function () {
                        return {
                            ticket: id,
                            centralStation: centralStation
                        };
                    }
                }
            });*/

            modalInstance.result.then(function (item) {
                if (action === 'requests' && item === 'new')
                    $route.reload();
            }, function () {
            });
        };

    self.openNewRequestModal =
        function () {
            var modalInstance = $uibModal.open({
                animation: true,
                component: 'request',
                size: 'md',
                resolve: {
                    edit: function () {
                        return false;
                    }
                }
            });

            modalInstance.result.then(function (item) {
                if (action === 'requests' && item === 'new')
                    $route.reload();
            }, function () {
            });
        };


}