/**
 * Created by Usuario 02 on 29-07-2015.
 */

'use strict';

angular.module('cloudxcmClientsApp')
  .controller('FooterCtrl',['$scope', 'User',
      function ($scope, User) {
    User.get(function(success){
      if(success.centralStationClient)
        $scope.appClient = 'client';
      else {
        $scope.appClient = 'technician';
      }
    });
  }]);



