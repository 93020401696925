(function(){
    'use strict';

    var ticketNote = {
        bindings: {
            resolve:'<',
            modalInstance:'<'
        },
        templateUrl: 'app/support2/tickets/modal/note/ticket.note.html',
        controller: 'TicketNoteCtrlv2 as vm'
    };

    angular
        .module('cloudxcmClientsApp')
        .component('ticketNote', ticketNote);

})();