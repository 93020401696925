(function(){
    'use strict';

    var alarm = {
        bindings: {
            resolve:'<',
            modalInstance:'<'
        },
        templateUrl: 'app/alarms2/modal/detail/alarm.detail.html',
        controller: 'AlarmDetailCtrlv2 as vm'
    };

    angular
        .module('cloudxcmClientsApp')
        .component('alarm', alarm);

})();