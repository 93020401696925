(function () {
    'use strict';

    angular.module('cloudxcmClientsApp')
        .factory('CloudIconsv2', CloudIconsv2);


    CloudIconsv2.$inject = ['$http', '$cookies'];
    config.$inject = ['$routeProvider'];


    function CloudIconsv2($http, $cookies) {
        var self = this;

        self.response = {
            getAll: getAll,
            getColors: getColors
        };

        return self.response;

        function getAll() {
            var url = '/api2/central-station-client-icons';
            return $http.get(url);
        }

        function getColors() {
            var url = '/api2/central-station-client-colors';
            return $http.get(url);
        }
    }
})();