(function () {
    'use strict';

    angular.module('cloudxcmClientsApp')
        .factory('Accountsv2', Accountsv2)
        .config(config);

    Accountsv2.$inject = ['$http', '$cookies'];
    config.$inject = ['$routeProvider'];


    function Accountsv2($http, $cookies) {
        var self = this;
        self.response = {
            byUser: byUser,
            getDetail: getDetail,
            getByCentralStation: getByCentralStation,
            updateCloudIconConfig: updateCloudIconConfig,
            getMinimalBycentralStation: getMinimalBycentralStation
        };

        return self.response;

        function byUser() {
            var idClient = $cookies.get('idClient');
            var url = '/api2/central-station-clients/' + idClient + '/accounts/?' +
                'populate=idCentralStation'
                +'&include=idCouch'
                +'&include=centralStation.idCentralStation'
                +'&include=centralStation.cloudImage'
                +'&include=centralStation.companyInfo.name'
                +'&include=cloudIcon.image.svgPath'
                +'&include=cloudIcon.color.value'
                +'&include=name'
                +'&include=location.address'
                +'&include=location.region.formattedAddress';

            return $http.get(url);
        }

        function getByCentralStation(cs, skip, limit) {
            var idClient = $cookies.get('idClient');
            var url = "/api2/central-station-clients/" + idClient + "/central-stations/" + cs + "/accounts?populate=location.idRegion" +
                "&include=name" +
                "&include=idCouch" +
                "&include=cloudIcon.image.svgPath" +
                "&include=cloudIcon.color.value" +
                "&include=location.address" +
                "&include=location.region.formattedAddress" +
                "&include=lastStatus.status" +
                "&include=lastStatus.accountUser" +
                "&include=lastStatus.date" +
                "&include=lastStatus.accountUser.name" +
                "&include=location.coordinates.latitude" +
                "&include=location.coordinates.longitude" +
                "&include=location.address" +
                "&include=location.zipCode" +
                "&skip=" + skip + "&limit=" + limit;
            return $http.get(url);
        }

        function getDetail(cs, account) {
            var idClient = $cookies.get('idClient');
            var url = '/api2/central-station-clients/' + idClient + '/central-stations/' + cs + '/accounts/' + account +
                "/?populate=location.idRegion&virtuals=debt"
                + "&include=iconStatus"
                + "&include=number"
                + "&include=name"
                + "&include=serviceType"
                + "&include=status"
                + "&include=debt"
                + "&include=lastStatus.datetimeAccount"
                + "&include=lastStatus.status"
                + "&include=location.coordinates.latitude"
                + "&include=location.coordinates.longitude"
                + "&include=cloudIcon.image.svgPath"
                + "&include=cloudIcon.color.value"
                + "&include=lastStatus.accountUser"
                + "&include=location.address"
                + "&include=location.zipCode";
            return $http.get(url);
        }

        function updateCloudIconConfig(cs, account, data) {
            var idClient = $cookies.get('idClient');
            var url = '/api2/central-station-clients/' + idClient + '/central-stations/' + cs + '/accounts/' + account + '/cloud-icon';
            return $http.patch(url, data);
        }

        function getMinimalBycentralStation(cs) {
            var idClient = $cookies.get('idClient');
            var url = "/api2/central-station-clients/" + idClient + "/central-stations/" + cs + "/accounts?include=idCouch&include=name&populate=location.idRegion&include=cloudIcon&include=location.region.formattedAddress&include=serviceType&virtuals=debt&include=debt";
            return $http.get(url);
        }
    }

    function config($routeProvider) {
        $routeProvider
            .when('/accounts', {
                templateUrl: 'app/accounts2/accounts.html',
                controller: 'AccountsCtrlv2 as vm',
                label: 'SUPPORT_MANAGEMENT'
            });
    }

})();


