(function(){
    'use strict';

    var paymentMethod = {
        bindings: {
            resolve:'<',
            modalInstance:'<'
        },
        templateUrl: 'app/payment2/modal/payment-method/payment-method.html',
        controller: 'PaymentMethodCtrlv2 as vm'
    };

    angular
        .module('cloudxcmClientsApp')
        .component('paymentMethod', paymentMethod);

})();