'use strict';

angular.module('cloudxcmClientsApp')
  .factory('User',['$location', '$rootScope', '$http', '$cookies', '$q', '$interval', 'lodash', '$cookieStore', '$window','$localStorage',
      function ($location, $rootScope, $http, $cookies, $q, $interval, lodash, $cookieStore, $window,$localStorage) {

    return {
      get: function (callback) {
        var cb = callback || angular.noop;
        var defer = $q.defer();

        if($localStorage.currentUser){
          defer.resolve($localStorage.currentUser);
          return defer.promise;
        }

        $http.get('/api/central-station-clients/me')
          .success(function (data) {
            defer.resolve(data);
            return cb(data);
          })
          .error(function (err) {
            defer.reject(err);
            return cb(err);
          });
        return defer.promise;
      }

    }
      ;

  }]);
