'use strict';

angular.module('cloudxcmClientsApp')
    .controller('AlarmsCtrlv2', AlarmsCtrlv2);

AlarmsCtrlv2.$inject = ['Auth', 'Alarmsv2', 'SweetAlert', 'lodash', '$uibModal', '$rootScope', '$translate'];

function AlarmsCtrlv2(Auth, Alarmsv2, SweetAlert, lodash, $uibModal, $rootScope, $translate) {
    var self = this;
    $rootScope.titulo = "ALARMS_MANAGEMENT";
    delete $rootScope.subtitulo;

    self.centralStations = [];
    self.centralStationsRdy = false;
    self.currentCs = null;
    self.alarms = [];
    self.query = true;
    self.updateInProgress = false;
    self.noMoreDataAvaliable = false;
    self.resultByPage = 10;
    self.currentPage = 0;


    Auth.getCurrentUser()
        .then(
            handleUser,
            handleError
        );

    function handleUser(data) {
        self.centralStations = lodash.map(data.centralStationClient.centralStations, 'centralStation');
        self.centralStationsRdy = true;
    }


    self.getColorByStatus =
        function (status) {
            switch (status) {
                case "PENDING":
                    return "#FF0000";
                case "ON_HOLD":
                    return "#FF0000";
                case "ON_ATTENTION":
                    return "#FFD700";
                case "ATTENDED":
                    return "#FF0000";
            }
        };


    self.getCurrentIcon =
        function (classification) {
            var local = lodash.find(self.iconAll, {classification: classification.key});
            if (local) {
                var icon = lodash.find(local.subClassification, {name: classification.subClassification ? classification.subClassification.key : 'OPENINGS_CLOSINGS'});
                if (icon)
                    return icon.icon;
                else
                    return 'icon icon-ico-ea-aperturas-cierres';

            }
        };


    self.calcDate =
        function (date) {

            var currDate = moment(date);
            var today = moment(new Date);
            var yesterday = moment(today.subtract(1, 'day'));
            var prefix = '';

            currDate = currDate.format("DD-MM-YYYY");
            today = today.format("DD-MM-YYYY");
            yesterday = yesterday.format("DD-MM-YYYY");

            if (currDate === today) {
                prefix = $translate.instant('TODAY');

            }
            else if (currDate === yesterday) {
                prefix = $translate.instant('YESTERDAY');

            }
            else {
                prefix = currDate;

            }

            return prefix;
        };

    self.transformAlarm =
        function (alarm) {

            var horaAlarma = moment.utc(alarm.createdAtLocal);
            var classificationIcon = "";
            var icon = "";


            if (!alarm.account.data.cloudIcon) {
                alarm.account.data.cloudIcon = {
                    image: {
                        svgPath: "M 32.5614 243 L 103.2982 243 L 103.2982 159.9756 L 152.7018 159.9756 L 152.7018 243 L 228.4912 243 L 227.9298 117.4312 L 256 137.0878 L 256 113.5156 L 227.9298 93.859 L 227.9298 13 L 196.4912 13 L 196.4912 71.8463 L 128 23.8829 L 0 113.5156 L 0 137.0878 L 32.5614 114.2841 L 32.5614 243 Z"
                    }
                }
            }

            alarm.color = self.getColorByStatus(alarm.status);
            alarm.icon = self.getCurrentIcon(alarm.stateRule.classification);
            alarm.createdAtLocal = self.calcDate(alarm.createdAtLocal) + ' ' + moment.utc(alarm.createdAtLocal).format("HH:mm:ss");
            self.alarms.push(alarm);
        };

    function handleAlarms(data) {
        lodash.forEach(data.data, self.transformAlarm);
        self.currentPage++;
        self.query = false;
        self.updateInProgress = false;
        if (data.data.length === 0)
            self.noMoreDataAvaliable = true;

    }

    function handleError(err) {
        self.query = false;
        self.centralStationsRdy = true;
        console.log(err);
    }


    self.refresh =
        function (cs) {
            self.currentCs = null;
            self.fetchAlarms(cs);

        };


    self.fetchAlarms =
        function (cs) {
            if (cs) {
                if (self.currentCs !== cs) {
                    self.currentPage = 0;
                    self.currentCs = cs;
                    self.alarms = [];
                    self.query = true;
                    self.updateInProgress = false;
                    self.noMoreDataAvaliable = false;
                }

                if (self.updateInProgress)
                    return;
                else
                    self.updateInProgress = true;


                Alarmsv2.getByCentralStation(cs, self.resultByPage * self.currentPage, self.resultByPage)
                    .then(handleAlarms, handleError);
            }
        };


    self.openAlarmsModal =
        function (centralStation, id) {
            var modalInstance = $uibModal.open({
                animation: true,
                component: 'alarm',
                size: 'md',
                resolve: {
                    idCentralStation: function () {
                        return centralStation
                    },
                    idAlarm: function () {
                        return id;
                    }

                }
            });
        };


    self.iconAll = [
        {
            classification: 'EMERGENCIES',
            subClassification: [
                {
                    name: 'MEDICAL',
                    icon: 'icon icon-ico-ea-medico'
                },
                {
                    name: 'FIRE',
                    icon: 'icon icon-ico-ea-fuego'
                },
                {
                    name: 'FLOOD',
                    icon: 'icon icon-ico-ea-inundacion'
                },
                {
                    name: 'INTRUSION',
                    icon: 'icon icon-ico-ea-intrusion'
                },
                {
                    name: 'EMERGENCIES',
                    icon: 'icon icon-ico-ea-emergencia'
                },
                {
                    name: 'PANIC',
                    icon: 'icon icon-ico-ea-panico'
                },
                {
                    name: 'ASSAULT',
                    icon: 'icon icon-ico-ea-robo'
                }
            ]
        }, {
            classification: 'MOBILE_PANIC',
            subClassification: [
                {
                    name: 'MEDICAL',
                    icon: 'icon icon-ico-ea-panico-movil-medico-alt'
                },
                {
                    name: 'FIRE',
                    icon: 'icon icon-ico-ea-panico-movil-fuego-alt'
                },
                {
                    name: 'ASSAULT',
                    icon: 'icon icon-ico-ea-panico-movil-robo-alt'
                },
                {
                    name: 'ASSISTANCE',
                    icon: 'icon icon-ico-ea-panico-movil-asistencia-alt'
                }
            ]
        }, {
            classification: 'OPENINGS_CLOSINGS',
            subClassification: [
                {
                    name: 'OPENING',
                    icon: 'icon icon-ico-ea-apertura'
                },
                {
                    name: 'CLOSING',
                    icon: 'icon icon-ico-ea-cierre'
                },
                {
                    name: 'OPENINGS_CLOSINGS',
                    icon: 'icon icon-ico-ea-aperturas-cierres'
                },
                {
                    name: 'EARLY_OPENING',
                    icon: 'icon icon-ico-ea-cierre-temprano'
                },
                {
                    name: 'LATE_CLOSING',
                    icon: 'icon icon-ico-ea-cierre-tarde'
                },
                {
                    name: 'OPENING_OUT_TIME',
                    icon: 'icon icon-ico-ea-panico-movil-robo-alt'
                },
                {
                    name: 'CLOSING_OUT_TIME',
                    icon: 'icon icon-ico-ea-panico-movil-fuego-alt'
                },
                {
                    name: 'FAILED_OPENING',
                    icon: 'icon icon-ico-ea-panico-movil-robo-alt'
                },
                {
                    name: 'FAILED_CLOSING',
                    icon: 'icon icon-ico-ea-panico-movil-fuego-alt'
                },
                {
                    name: 'ABSENCE_OPENING',
                    icon: 'icon icon-ico-ea-apertura-ausencia'
                },
                {
                    name: 'ABSENCE_CLOSING',
                    icon: 'icon icon-ico-ea-cierre-ausencia'
                },
                {
                    name: 'REMOTE_OPENING',
                    icon: 'icon icon-ico-ea-panico-movil-robo-alt'
                },
                {
                    name: 'AUTO_ARM_FAILED',
                    icon: 'icon icon-ico-ea-panico-movil-asistencia-alt'
                },
                {
                    name: 'AUTOMATIC_OPENING',
                    icon: 'icon icon-ico-ea-panico-movil-robo-alt'
                },
                {
                    name: 'AUTOMATIC_CLOSING',
                    icon: 'icon icon-ico-ea-panico-movil-fuego-alt'
                }
            ]
        }, {
            classification: 'TEST',
            subClassification: [
                {
                    name: 'ABSENCE_REPORT',
                    icon: 'icon icon-ico-ea-panico-movil-medico-alt'
                },
                {
                    name: 'ZONE_TEST',
                    icon: 'icon icon-ico-ea-panico-movil-fuego-alt'
                },
                {
                    name: 'REPORT',
                    icon: 'icon icon-ico-ea-panico-movil-robo-alt'
                }
            ]
        }, {
            classification: 'FAULT',
            subClassification: [
                {
                    name: 'BATTERY_FAULT',
                    icon: 'icon icon-ico-ea-panico-movil-medico-alt'
                },
                {
                    name: 'ENERGY_FAULT',
                    icon: 'icon icon-ico-ea-panico-movil-fuego-alt'
                },
                {
                    name: 'ZONE_FAULT',
                    icon: 'icon icon-ico-ea-panico-movil-robo-alt'
                }
            ]
        }
    ];


}