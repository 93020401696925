/**
 * Created by
 */
angular.module('cloudxcmClientsApp')
    .directive('googleMap', [
        'lodash', '$translate', '$http',
        function (lodash, $translate, $http) {
            return {
                restrict: 'E',
                templateUrl: 'components/directives/google-map/google-map.html',
                scope: {
                    positions: "=",
                    instance: "@",
                    latitude: "@",
                    longitude: "@"
                },
                link: function ($scope, element, attrs) {


                    var latitude = $scope.latitude;
                    var longitude = $scope.longitude;
                    var icon = $scope.icon;
                    $scope.instance = $scope.instance || 'maps_canvas';
                    var LatLng = new google.maps.LatLng($scope.positions[0].latitude, $scope.positions[0].longitude);

                    var mapOptions = {
                        zoom: 10,
                        center: LatLng,
                        panControl: false,
                        zoomControl: true,
                        scaleControl: false,
                        draggable: true,
                        scrollwheel: true
                    };


                    setTimeout(
                        function () {
                            var map2 = new google.maps.Map(document.getElementById($scope.instance), mapOptions);

                            $scope.pinSymbol = function (color, icon) {
                                return {
                                    // path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
                                    path: icon,
                                    fillColor: color,
                                    fillOpacity: 1,
                                    strokeColor: '#000',
                                    strokeWeight: 2,
                                    scale: 1
                                };
                            };

                            lodash.forEach($scope.positions, function (position) {
                                var marker = new google.maps.Marker({
                                    position: new google.maps.LatLng(position.latitude, position.longitude),
                                    map: map2,
                                    icon: {
                                        path: position.icon,
                                        fillColor: position.color,
                                        fillOpacity: 1, // opacidad del relleno*/
                                        strokeWeight: 0, //grosor del borde
                                        scale: 0.12, //tamaño
                                        scaleControl: true,
                                        scaledSize: new google.maps.Size(25, 25),
                                        origin: new google.maps.Point(0, 0),
                                        anchor: new google.maps.Point(0, 0)
                                    }
                                });
                                var x = -5;
                                var y = 25;
                                if (position.icon) {
                                    x = -30;
                                    y = -5;
                                }
                                ;
                                var pos = new google.maps.LatLng(position.latitude, position.longitude);
                                if (position.iconAditional) {
                                    var marker2 = new google.maps.Marker({
                                        position: pos,
                                        map: map2,
                                        icon: {
                                            url: position.iconAditional,
                                            scaleControl: true,
                                            scaledSize: new google.maps.Size(25, 25),
                                            origin: new google.maps.Point(0, 0),
                                            anchor: new google.maps.Point(x, y)
                                        }
                                    });
                                }

                                var infowindow = new google.maps.InfoWindow({
                                    content: position.information
                                });

                                marker.addListener('click', function () {
                                    infowindow.open(map2, marker);
                                });
                            });

                        },
                        15
                    );


                }
            }
        }]);
