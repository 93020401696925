(function () {
    'use strict';

    angular.module('cloudxcmClientsApp')
        .controller('TicketLogCtrlv2', TicketLogCtrlv2);

    TicketLogCtrlv2.$inject = ['Auth', 'Ticketsv2', 'SweetAlert', 'lodash', '$uibModal', '$timeout', '$translate', 'DTOptionsBuilder', 'DTColumnBuilder', '$filter'];


    function TicketLogCtrlv2(Auth, Ticketsv2, SweetAlert, lodash, $uibModal, $timeout, $translate, DTOptionsBuilder, DTColumnBuilder, $filter) {
        var self = this;
        self.Ticket = {};
        self.init = true;


        self.$onInit =
            function () {
                self.dtOptions = DTOptionsBuilder.fromFnPromise(
                    function () {
                        return self.fetchTicket();
                    }
                ).withBootstrap().withOption('order', [0, "asc"]);
                self.dtColumns = [
                    DTColumnBuilder.newColumn('createdAt').notVisible(),

                    DTColumnBuilder.newColumn('event').withTitle($translate.instant('EVENT')).notSortable()
                        .renderWith(function (data) {
                                return $translate.instant(data);
                            }
                        ),

                    DTColumnBuilder.newColumn('currDate').withTitle($translate.instant('DATE')),

                    DTColumnBuilder.newColumn('difeDay').withTitle($translate.instant('TIME')),

                    DTColumnBuilder.newColumn('logger.observations').withTitle($translate.instant('COMMENT')),

                    DTColumnBuilder.newColumn('logger.name').withTitle($translate.instant('SOURCE'))




                ];


            };


        self.fetchTicket =
            function () {

                return Ticketsv2.getLog(self.resolve.idCentralStation, self.resolve.idTicket)
                    .then(handleTicket, handleErr);
            };


        function handleTicket(data) {

            self.ticket = data.data;

            self.init = false;
            self.log = lodash.sortBy(self.ticket.log, ['createdAt']);
            console.log(self.log);


            lodash.forEach(self.log,
                function (entry, index) {
                    console.log(entry.createdAt);
                    var minute = 1000 * 60;
                    var hour = minute * 60;
                    var day = hour * 24;
                    if (index + 1 < self.log.length) {
                        var difeDay = moment(Math.abs(moment.utc(entry.createdAt) - moment.utc(self.log[index + 1].createdAt)));
                        if ((Math.round(difeDay / day)) > 0)
                            entry.difeDay = (Math.round(difeDay / day)) + ' ' + $translate.instant('DAYS');
                        else if ((Math.round(difeDay / hour)) > 0)
                            entry.difeDay = (Math.round(difeDay / hour)) + ' ' + $translate.instant('HOURS');
                        else
                            entry.difeDay = (Math.round(difeDay / minute)) + ' ' + $translate.instant('MINUTES');
                    } else {
                        entry.difeDay = '-';

                    }

                    entry.currDate = lodash.capitalize(moment.utc(entry.createdAt).format('MMM DD, YYYY h:mm a'));

                    entry.logger.observations = entry.logger.observations || '-';

                }
            );


            return self.log;


        }


        function handleErr(err) {

            console.log(err);
            return err;
        }


        self.cancel =
            function () {
                self.modalInstance.dismiss('cancel');
            }


    }

})();