(function () {
    'use strict';

    angular.module('cloudxcmClientsApp')
        .factory('Billsv2', Billsv2)
        .config(config);

    Billsv2.$inject = ['$http', '$cookies'];
    config.$inject = ['$routeProvider'];


    function Billsv2($http, $cookies) {
        var self = this;

        self.response = {
            getByCentralStation: getByCentralStation,
            getPaiByCentralStation:getPaiByCentralStation,
            getDetail:getDetail
        };

        return self.response;

        function getByCentralStation(cs, acc, skip, limit) {
            var idClient = $cookies.get('idClient');
            var url = "/api2/central-station-clients/" + idClient + "/central-stations/" + cs + "/receipts?filters[idAccount]=" + acc + "&filters[status]=PENDING&filters[status]=PAST_DUE"
                +"&include=_id"
                +"&include=status"
                +"&include=proforma.number"
                +"&include=proforma.client.data.name"
                +"&include=createdAt"
                +"&include=proforma.account.data.number"
                +"&include=proforma.account.data.name"
                +"&include=proforma.account.additional.fiscalId"
                +"&include=proforma.account.additional.phone"
                +"&include=proforma.client.data.email"
                +"&include=proforma.account.additional.formattedAddress"
                +"&include=proforma.concepts"
                +"&include=data.price"
                +"&include=proforma.total"
                +"&skip=" + skip + "&limit=" + limit;
            return $http.get(url);
        }

        function getDetail(cs, account) {
            var idClient = $cookies.get('idClient');
            var url = '/api2/central-station-clients/' + idClient + '/central-stations/' + cs + '/Bills/' + account +
                "/?populate=location.idRegion";
            return $http.get(url);
        }

        function getPaiByCentralStation(cs, acc, skip, limit) {
            var idClient = $cookies.get('idClient');
            var url = "/api2/central-station-clients/" + idClient + "/central-stations/" + cs + "/receipts?filters[idAccount]=" + acc + "&filters[status]=PAID&skip=" + skip + "&limit=" + limit;
            return $http.get(url);
        }
    }

    function config($routeProvider) {
        $routeProvider
            .when('/bills', {
                templateUrl: 'app/invoicing2/bills/bills.html',
                controller: 'BillsCtrlv2 as vm',
                label: 'SUPPORT_MANAGEMENT'
            });
    }

})();


