'use strict';

angular.module('cloudxcmClientsApp')
    .controller('TicketsCtrlv2', TicketsCtrlv2);

TicketsCtrlv2.$inject = ['Ticketsv2', 'Auth', 'lodash', '$uibModal', '$translate', '$cookies', '$rootScope'];


function TicketsCtrlv2(Ticketsv2, Auth, lodash, $uibModal, $translate, $cookies, $rootScope) {
    var self = this;
    $rootScope.titulo = "SUPPORT";
    $rootScope.subtitulo = "TICKET";
    self.centralStationsRdy = false;
    self.centralStations = [];
    self.currentCs = null;
    self.tickets = [];
    self.query = true;
    self.updateInProgress = false;
    self.noMoreDataAvaliable = false;
    self.resultByPage = 10;
    self.currentPage = 0;


    Auth.getCurrentUser()
        .then(
            handleUser,
            handleError
        );

    function handleUser(data) {
        self.centralStations = lodash.map(data.centralStationClient.centralStations, 'centralStation');
        self.centralStationsRdy = true;
    }

    function handleError(err) {
        self.query = false;
        self.centralStationsRdy = true;
        console.log(err);
    }


    self.refresh =
        function (cs) {
            self.currentCs = null;
            self.fetchTickets(cs);

        };

    self.transformTicket =
        function (ticket) {

            var horaTicket = moment.utc(ticket.createdAtLocal);
            var classificationIcon = "";
            var icon = "";


            if (!ticket.account.cloudIcon) {
                ticket.account.cloudIcon =
                    {
                        image: {
                            value: "https://nevulacsblobmetaqa.blob.core.windows.net/metacenter/undefined/8d270375-28f3-4b93-bda8-86255d190583",
                            svgPath: 'M 32.5614 243 L 103.2982 243 L 103.2982 159.9756 L 152.7018 159.9756 L 152.7018 243 L 228.4912 243 L 227.9298 117.4312 L 256 137.0878 L 256 113.5156 L 227.9298 93.859 L 227.9298 13 L 196.4912 13 L 196.4912 71.8463 L 128 23.8829 L 0 113.5156 L 0 137.0878 L 32.5614 114.2841 L 32.5614 243 Z',

                        },
                        color: {
                            value: '#000000'
                        }
                    }
            }

            if (ticket.origin) {
                ticket.origin = $translate.instant('CREATED_BY') + $translate.instant(ticket.origin);
            }

            var appointedDate = self.calcDate(ticket.createdAt);

            if (ticket.status === 'ASSIGNED' || ticket.status === 'REVOKED') {
                ticket.attentionDate = $translate.instant('PLANNED');
                ticket.attentionDate += ' ' + appointedDate.date;

                ticket.iconStatus = 'icon icon-ico-cloud-ticket-planificado';
            } else if (ticket.status === 'OPENED' || ticket.status === 'IN_PROGRESS') {
                ticket.attentionDate = $translate.instant(ticket.status);
                ticket.attentionDate += ' ' + appointedDate.date;
                ticket.iconStatus = 'icon icon-ico-cloud-ticket-en-proceso';
            } else if (ticket.status === 'ON_PAUSE') {
                ticket.attentionDate = $translate.instant(ticket.status);
                ticket.attentionDate += ' ' + appointedDate.date;
                ticket.iconStatus = 'icon icon-ico-cloud-ticket';
            } else if (ticket.status === 'FINISHED') {
                ticket.attentionDate = $translate.instant(ticket.status);
                ticket.attentionDate += ' ' + appointedDate.date;

                ticket.iconStatus = 'icon icon-ico-cloud-ticket-finalizado';
            }
            else {
                ticket.attentionDate = $translate.instant(ticket.status);
                ticket.attentionDate += ' ' + appointedDate.date;
                ticket.iconStatus = 'icon icon-ico-cloud-ticket';
            }


            lodash.forEach(ticket.requests,
                function (request, index) {
                    if (index > 0)
                        ticket.requestName += ' - ' + request.request.incidence;
                    else
                        ticket.requestName = request.request.incidence;


                }
            );


            ticket.createdAt = self.calcDate(ticket.createdAt).date + ' ' + moment.utc(ticket.createdAt).format("HH:mm");
            self.tickets.push(ticket);
        };

    self.calcDate =
        function (date) {

            var currDate = moment.utc(date);
            var today = moment();
            var yesterday = moment().subtract(1, 'day');
            var prefix = '';

            currDate = currDate.format("DD-MM-YYYY");
            today = today.format("DD-MM-YYYY");
            yesterday = yesterday.format("DD-MM-YYYY");

            if (currDate === today) {
                prefix = $translate.instant('TODAY');

            }
            else if (currDate === yesterday) {
                prefix = $translate.instant('YESTERDAY');

            }
            else {
                prefix = currDate;

            }


            prefix = {date: prefix};
            prefix['hour'] = moment(date).format('HH:mm');

            return prefix;
        };

    function handleTickets(data) {
        lodash.forEach(data.data, self.transformTicket);
        self.currentPage++;
        self.query = false;
        self.updateInProgress = false;
        if (data.data.length === 0)
            self.noMoreDataAvaliable = true;

    }


    self.fetchTickets =
        function (cs) {
            if (cs) {
                if (self.currentCs !== cs) {
                    self.currentPage = 0;
                    self.currentCs = cs;
                    self.tickets = [];
                    self.query = true;
                    self.updateInProgress = false;
                    self.noMoreDataAvaliable = false;
                }

                if (self.updateInProgress)
                    return;
                else
                    self.updateInProgress = true;


                Ticketsv2
                    .getByCentralStation(cs.idCentralStation, self.resultByPage * self.currentPage, self.resultByPage)
                    .then(handleTickets, handleError);
            }
        };


    self.openTicketModal =
        function (centralStation, id) {
            var modalInstance = $uibModal.open({
                animation: true,
                component: 'ticket',
                size: 'md',
                resolve: {
                    idCentralStation: function () {
                        return centralStation
                    },
                    idTicket: function () {
                        return id;
                    }
                }
            });


            /*
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/support/clients/modal-ticket.html',
                controller: 'ModalTicketClientsCtrl',
                size: 'md',
                resolve: {
                    items: function () {
                        return {
                            ticket: id,
                            centralStation: centralStation
                        };
                    }
                }
            });
            */


            modalInstance.result.then(function (item) {
                if (action === 'tickets' && item === 'new')
                    $route.reload();
            }, function () {
            });
        };


    self.log =
        function (centralStation, id) {
            var modalInstance = $uibModal.open({
                    animation: true,
                    component: 'ticketLog',
                    size: 'md',
                    resolve: {
                        idCentralStation: function () {
                            return centralStation
                        },
                        idTicket: function () {
                            return id;
                        }
                    }
                }
            );

            /*

                var modalInstance = $uibModal.open({
                    templateUrl: 'app/support/ticket-log/ticket-log.html',
                    controller: 'TicketLogCtrl',
                    backdrop: "static",
                    size: 'lg',
                    resolve: {
                        items: function () {
                            return ticket.idCouch;
                        },
                        center: function () {
                            return ticket.idCentralStation;
                        },
                        rol: function () {
                            return 'clients';
                        }
                    }
                });
                */
        };


    self.newNote =
        function (ticket, cs) {
            var modalInstance = $uibModal.open({
                animation: true,
                component: 'ticketNote',
                size: 'md',
                resolve: {
                    idCentralStation: function () {
                        return cs
                    },
                    idTicket: function () {
                        return ticket;
                    }
                }
            });


            modalInstance.result.then(function (item) {
                if (action === 'tickets' && item === 'new')
                    $route.reload();
            }, function () {
            });


        };


}