'use strict';

angular.module('cloudxcmClientsApp')
  .factory( 'Auth',
      ['$location', '$rootScope','$http', '$cookies', '$q' ,'$interval', 'lodash','$cookieStore', '$window','$localStorage','User',
      function Auth($location, $rootScope, $http, $cookies, $q ,$interval, lodash,$cookieStore, $window,$localStorage,User) {
    var currentUser = {};
    /*if($cookies.get('token')) {
     currentUser = User.get();
     }*/

    return {
      /**
       * Authenticate user and save token
       *
       * @param  {Object}   user     - login info
       * @param  {Function} callback - optional
       * @return {Promise}
       */
      login: function(authCode, callback) {
        var cb = callback || angular.noop;
        var deferred = $q.defer();
        $http.post('/auth/clients/authorization-code', authCode)
          .then(function(data) {
              $cookieStore.put('token', data.data.id_token);
              $cookieStore.put('refreshToken', data.data.refresh_token);
              deferred.resolve(data);
              return cb();
            },
            function(err) {
              this.logout();
              deferred.reject(err);
              return cb(err);
            }.bind(this));
        return deferred.promise;
      },
      /**
       * Delete access token and user info
       *
       * @param  {Function}
       */
      logout: function() {
        $cookieStore.remove('token');
        $cookieStore.remove('refreshToken');
        $cookieStore.remove('idClient');
        //$rootScope.logout = true;
        currentUser = {};
        $localStorage.currentUser=undefined;
        //$rootScope.$broadcast('scanner-started', { isLoggedIn:false });
        window.location = 'https://login.microsoftonline.com/nevulaservicespr.onmicrosoft.com/oauth2/v2.0/logout?p=B2C_1_SIGN_UP_OR_SIGN_IN&post_logout_redirect_uri=https://clients.nevula.com';

      },

      refreshLogin: function (refreshToken, callback){
        refreshToken={refreshToken:refreshToken};
        var cb = callback || angular.noop;
        var deferred = $q.defer();
        $http.post('/auth/clients/refresh-token', refreshToken)
          .then(function(data) {
              $cookieStore.put('token', data.data.id_token);
              $cookieStore.put('refreshToken', data.data.refresh_token);
              $rootScope.isLoggedIn = true;
              $location.url('/alarms');
              deferred.resolve(data);
              return cb();
            },
            function(err) {
              this.logout();
              deferred.reject(err);
              return cb(err);
            }.bind(this));

        return deferred.promise;
      },
      /**
       * Gets all available info on authenticated user
       *
       * @return {Object} user
       */
      getCurrentUser: function(callback) {
        var cb = callback || angular.noop;
        var deferred = $q.defer();
        if($localStorage.currentUser){
          console.log("este es el  que retorna");
          console.log($localStorage.currentUser);
          console.log("returning this user");
          console.log("fy");
          deferred.resolve($localStorage.currentUser);
          cb($localStorage.currentUser);
          return deferred.promise;
        }
        $http.get('/api/central-station-clients/me?populate=centralStations.idCentralStation&populate=centralStations.centralStation.companyInfo.location.idRegion')
          .then(function(data) {
              $localStorage.currentUser=data.data;
              currentUser=data.data;
              deferred.resolve(data.data);
              return cb(data.data);
            },
            function(err) {
                if(err.data === "UNAUTHORIZED")
                    $location.path("/pages/not_registered");
                else
                    this.logout();
            }.bind(this));

        return deferred.promise;

      },
      resetPassword: function () {
        window.location = 'https://login.microsoftonline.com/nevulaservicespr.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_RESET&client_id=b20e71d3-4be0-4281-b42a-fc6a8ea8bf66&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fclients.nevula.com&scope=openid&response_type=id_token&prompt=login';
      },
      /**
       * Get auth token
       */
      getToken: function() {
        return $cookies.get('token');
      }
      /**
       * Change password
       *
       * @param  {String}   oldPassword
       * @param  {String}   newPassword
       * @param  {Function} callback    - optional
       * @return {Promise}
       */
      /*changePassword: function(oldPassword, newPassword, callback) {
       var cb = callback || angular.noop;

       return User.changePassword({ id: currentUser._id }, {
       oldPassword: oldPassword,
       newPassword: newPassword
       }, function(user) {
       return cb(user);
       }, function(err) {
       return cb(err);
       }).$promise;
       },*/
      /**
       * Check if a user is logged in
       *
       * @return {Boolean}
       */
      /*     isLoggedIn: function() {
       return currentUser.hasOwnProperty('role');
       },*/

      /**
       * Waits for currentUser to resolve before checking if user is logged in
       */
      /*isLoggedInAsync: function(cb) {
       //console.log('verificando----->');
       var stop = $interval(function() {
       //console.log('saliendo----->');
       $cookies.remove('token');
       currentUser = {};
       $interval.cancel(stop);
       stop = undefined;
       return cb(false);
       }, 60000);
       if(currentUser.hasOwnProperty('$promise')) {
       currentUser.$promise.then(function() {
       $interval.cancel(stop);
       stop = undefined;
       cb(true);

       }).catch(function() {
       $interval.cancel(stop);
       stop = undefined;
       cb(false);
       });
       } else if(currentUser.hasOwnProperty('role')) {
       $interval.cancel(stop);
       stop = undefined;
       cb(true);
       } else {
       $interval.cancel(stop);
       stop = undefined;
       cb(false);
       }
       },*/

      /**
       * Check if a user is an admin
       *
       * @return {Boolean}
       */
      /*     isAdmin: function() {
       return currentUser.role === 'admin';
       },*/


      /*getPermissions: function (moduleName,callback) {
       var cb = callback || angular.noop;
       var defer = $q.defer();

       currentUser.$promise.then(function(response) {
       // var split  = $location.$$path.split("/");
       var searchArgs = {
       'uri': '/api/' + moduleName
       };

       //console.log(searchArgs);

       var array = lodash.filter(response.permissions,searchArgs);

       if (array.length > 0 ) {
       defer.resolve(array[0].methods);
       } else {
       defer.$$reject('No permissions records');
       }

       });
       return defer.promise;
       }*/
    };
  }]);
