'use strict';

angular.module('cloudxcmClientsApp')
    .controller('SupportCtrlv2', SupportCtrl);

SupportCtrl.$inject = [];


function SupportCtrl() {
    var self=this;
}