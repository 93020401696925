'use strict';

angular.module('cloudxcmClientsApp')
  .directive('uiMessage',['$translate', function ($translate) {
    return {
      templateUrl: 'components/directives/ui-message/ui-message.html',
      restrict: 'EA',
      replace:true,
      scope: {
        textmessage:"=",
        type:"@"
      },
      link: function ($scope, element, attrs) {

        //$scope.defaultText="";
        $scope.defaultText= {text : $translate.instant('NO_DATA_FOUND')};

      /*  switch($scope.type) {
          case "no-data-found":
            $scope.icon = "icon icon-ico-anterior";
            break;
        }*/

        console.log("----",$scope.defaultText.text);

       // $scope.defaultText=$scope.textmessage;







        }
    };
  }]);
