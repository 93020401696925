(function () {
    'use strict';

    var noData = {
        bindings: {
            msg: '@'
        },
        templateUrl: 'components/no-data/no-data.html'
    };

    angular
        .module('cloudxcmClientsApp')
        .component('noData', noData);

})();