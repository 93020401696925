'use strict';

angular.module('cloudxcmClientsApp')
  .directive('uiLoading', ['$translate',function ($translate) {
    return {
      templateUrl: 'components/directives/ui-loading/ui-loading.html',
      restrict: 'EA',
      scope: {
        showloading:"=",
        loadingText:"@",
        type:"@"
      },
      link: function ($scope, element, attrs) {

        if(!angular.isDefined($scope.loadingText)){
          $scope.loadingText=$translate.instant('LOADING');
        }
        if(!angular.isDefined($scope.showloading)){
          $scope.showloading=true;
        }
      }
    };
  }]);
