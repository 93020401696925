'use strict';

angular.module('cloudxcmClientsApp')
  .controller('NavbarCtrl', ['$rootScope', '$scope', '$location', 'Auth', '$compile', 'User',
      function ($rootScope, $scope, $location, Auth, $compile, User) {
    $("#main").removeClass("hideme");

    $scope.isCollapsed = true;
    $scope.isLoggedIn = Auth.isLoggedIn;
    $scope.isAdmin = Auth.isAdmin;

    Auth.getCurrentUser().then(function (data){
      $scope.getCurrentUser = data.azureUser;
    });

    User.get(function(success){
      if(success.centralStationClient)
        $scope.appClient = 'client';
      else {
        $scope.appClient = 'technician';
      }
    });

    $scope.logout = function() {
      Auth.logout();
    };

    $scope.changePassword=function(){
      Auth.resetPassword();
    };

    $scope.isActive = function(route) {
      return route === $location.path();
    };
    //$.AdminLTE.layout.fix();
    $compile($('nav'))($scope);

  }]);
