(function () {
    'use strict';

    angular.module('cloudxcmClientsApp')
        .controller('AccountDetailCtrlv2', AccountDetailCtrlv2);

    AccountDetailCtrlv2.$inject = ['Auth', 'Accountsv2', 'SweetAlert', 'lodash', '$uibModal', '$timeout'];


    function AccountDetailCtrlv2(Auth, Accountsv2, SweetAlert, lodash, $uibModal, $timeout) {
        var self = this;
        self.init = true;
        self.geocoder = new google.maps.Geocoder();


        self.$onInit =
            function () {
                self.centralStation = self.resolve.centralStation;
                self.fetchAccount();
            };

        self.statusAlarm = [
            {
                name: 'OPEN',
                key: 'DISARMED_BY',
                icon: 'http://nevulametacentro.blob.core.windows.net/metacenter/N_Disarmed.png'
            },{
                name: 'CLOSE',
                key: 'ARMED_BY',
                icon: 'http://nevulametacentro.blob.core.windows.net/metacenter/N_Armed_Away.png'
            }
        ];


        self.fetchAccount =
            function () {
                Accountsv2.getDetail(self.centralStation.idCentralStation, self.resolve.idAccount)
                    .then(handleAccount, handleErr);
            };


        function handleAccount(data) {

            self.init = false;
            var account = self.account = data.data;
            if (!account.cloudIcon) {
                account.cloudIcon = {
                    image: {
                        value: "https://nevulacsblobmetaqa.blob.core.windows.net/metacenter/undefined/8d270375-28f3-4b93-bda8-86255d190583",
                        svgPath: 'M 32.5614 243 L 103.2982 243 L 103.2982 159.9756 L 152.7018 159.9756 L 152.7018 243 L 228.4912 243 L 227.9298 117.4312 L 256 137.0878 L 256 113.5156 L 227.9298 93.859 L 227.9298 13 L 196.4912 13 L 196.4912 71.8463 L 128 23.8829 L 0 113.5156 L 0 137.0878 L 32.5614 114.2841 L 32.5614 243 Z',

                    },
                    color: {
                        value: '#000000'
                    }
                };
            }


            if (!account.lastStatus) {
                account.lastStatus = {status: 'OPEN', date: self.calcDate(moment())}
            } else {
                account.lastStatus.date = self.calcDate(account.lastStatus.datetimeAccount);
            }


            account.iconStatus = lodash.result(lodash.find(self.statusAlarm, {name: account.lastStatus.status}), 'icon');

            self.positions = [
                {
                    latitude: account.location.coordinates.latitude,
                    longitude: account.location.coordinates.longitude,
                    icon: account.cloudIcon.image.svgPath,
                    color: account.cloudIcon.color.value,
                    iconAditional:account.iconStatus,
                    information: account.location.address + ' - (' + account.location.zipCode + ')'

                }
            ];

        }

        self.calcDate =
            function (date) {

                var currDate = moment(date);
                var today = moment(new Date);
                var yesterday = moment(today.subtract(1, 'day'));
                var prefix = '';

                currDate = currDate.format("DD-MM-YYYY");
                today = today.format("DD-MM-YYYY");
                yesterday = yesterday.format("DD-MM-YYYY");

                if (currDate === today) {
                    prefix = $translate.instant('TODAY');

                }
                else if (currDate === yesterday) {
                    prefix = $translate.instant('YESTERDAY');

                }
                else {
                    prefix = currDate;
                }
                return prefix;
            };


        function handleErr(err) {
            console.log(err);
        }


        self.cancel =
            function () {
                self.modalInstance.dismiss('cancel');
            }


    }

})();