'use strict';

angular.module('cloudxcmClientsApp')
  .config(['$routeProvider',function ($routeProvider) {
    $routeProvider
      .when('/', {
        templateUrl: 'app/alarms/alarms.html',
        controller: 'AlarmsCtrl',
        label: "ALARMS"
      });
  }]);
