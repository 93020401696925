(function () {
    'use strict';

    angular.module('cloudxcmClientsApp')
        .controller('PaymentMethodCtrlv2', PaymentMethodCtrlv2);

    PaymentMethodCtrlv2.$inject = ['Auth', 'Paymentsv2', 'SweetAlert', 'lodash', 'Accountsv2', '$scope', '$translate', '$route'];

    function PaymentMethodCtrlv2(Auth, Paymentsv2, SweetAlert, lodash, Accountsv2, $scope, $translate, $route) {
        var self = this;
        self.init = true;
        self.methods = [];

        self.$onInit =
            function () {
                self.centralStation = self.resolve.centralStation;
                self.makePayment = !!(self.paymentData = self.resolve.paymentData);
                self.centralStation = self.resolve.centralStation;
                self.makePayment = !!(self.paymentData = self.resolve.paymentData);
                self.unregisteredPayment = false;
                self.init = self.makePayment;
                self.currYear = new Date().getFullYear();


                if (self.makePayment)
                    self.fetchMethods();

                self.months = [
                    {id: '01', name: 'JANUARY'},
                    {id: '02', name: 'FEBRUARY'},
                    {id: '03', name: 'MARCH'},
                    {id: '04', name: 'APRIL'},
                    {id: '05', name: 'MAY'},
                    {id: '06', name: 'JUNE'},
                    {id: '07', name: 'JULY'},
                    {id: '08', name: 'AUGUST'},
                    {id: '09', name: 'SEPTEMBER'},
                    {id: '10', name: 'OCTOBER'},
                    {id: '11', name: 'NOVEMBER'},
                    {id: '12', name: 'DECEMBER'}
                ];

                self.cardTypes = [
                    {id: '1', name: 'VISA', icon: 'fa fa-cc-visa'},
                    {id: '2', name: 'MASTERCARD', icon: 'fa fa-cc-mastercard'}
                ];

                self.countrys = [
                    {id: 'VE', name: 'Venezuela'},
                    {id: 'MX', name: 'Mexico'},
                    {id: 'BR', name: 'Brasil'}
                ];


            };

        function buildNewPaymentMethod() {
            var lang = 'es';
            var method = {
                idCentralStation: self.centralStation.idCentralStation,
                language: lang,
                creditCardToken: {
                    name: self.paymentMethod.namePrinted,
                    identificationNumber: self.paymentMethod.identificationCardmember,
                    paymentMethod: self.paymentMethod.cardType.name,
                    number: self.paymentMethod.creditcardNumber,
                    expirationDate: {
                        year: self.paymentMethod.expiration.year,
                        month: self.paymentMethod.expiration.month.id
                    },
                    payer: {
                        fullName: self.paymentMethod.nameCardmember,
                        emailAddress: self.paymentMethod.emailCardmember,
                        birthDate: self.paymentMethod.birthDate.year + '-' + self.paymentMethod.birthDate.month.id + '-' + self.paymentMethod.birthDate.day,
                        contactPhone: self.paymentMethod.phoneCardmember,
                        dniNumber: self.paymentMethod.identificationCardmember,
                        billingAddress: {
                            street1: self.paymentMethod.avenuebilling,
                            city: self.paymentMethod.citybilling,
                            state: self.paymentMethod.statebilling,
                            country: self.paymentMethod.countrybilling.id,
                            postalCode: self.paymentMethod.zipcodebilling,
                            phone: self.paymentMethod.phonebilling
                        }
                    }
                }


            };
            return method;
        }

        function buildNewPayment() {


            var currency = 'MXN';
            var payment = {
                receipts: self.resolve.paymentData.receipts,
                transaction: {
                    order: {
                        additionalValues: {
                            TX_VALUE: {
                                currency: currency
                            }
                        }
                    },
                    creditCard: {
                        securityCode: self.paymentMethod.securityCode
                    }
                }
            };


            if (!self.unregisteredPayment) {
                payment.idCreditCard = self.method._id
            } else {
                //CC RELATIVE
                payment.transaction.creditCard.number = self.paymentMethod.creditcardNumber;
                payment.transaction.creditCard.expirationDate = {
                    month: self.paymentMethod.expiration.month.id,
                    year: self.paymentMethod.expiration.year
                };
                payment.transaction.creditCard.name = self.paymentMethod.namePrinted;
                //CC KIND
                payment.transaction.paymentMethod = self.paymentMethod.cardType.name;
                //PAYER RELATIVE
                payment.transaction.payer = {
                    fullName: self.paymentMethod.nameCardmember,
                    emailAddress: self.paymentMethod.emailCardmember,
                    birthDate: self.paymentMethod.birthDate.year + '-' + self.paymentMethod.birthDate.month.id + '-' + self.paymentMethod.birthDate.day,
                    contactPhone: self.paymentMethod.phoneCardmember,
                    dniNumber: self.paymentMethod.identificationCardmember,
                    billingAddress: {
                        street1: self.paymentMethod.avenuebilling,
                        city: self.paymentMethod.citybilling,
                        state: self.paymentMethod.statebilling,
                        country: self.paymentMethod.countrybilling.id,
                        postalCode: self.paymentMethod.zipcodebilling,
                        phone: self.paymentMethod.phonebilling
                    }
                };
            }
            return payment;
        }


        self.unregistered =
            function () {
                self.unregisteredPayment = self.method._id === "1";

            };


        self.engageMechanism =
            function () {
                $scope.$broadcast('show-errors-check-validity');
                if (!self.paymentForm.$valid) {
                    SweetAlert.swal($translate.instant('ATTENTION'), $translate.instant('FIELDS_REQUIRED'), "error");
                    return;
                }

                //NEW PAYMENT
                if (self.makePayment) {
                    var payment = buildNewPayment();
                    console.log(payment);
                    Paymentsv2.registerPayment(self.centralStation.idCentralStation, payment)
                        .then(handlePaymentSuccess, handlePaymentErr);
                } else {
                    var method = buildNewPaymentMethod();
                    Paymentsv2.newPaymentMethod(self.centralStation.idCentralStation, method)
                        .then(handlePaymentMethodCreation, handlePaymentMethodErr);


                }


            };


        function handlePaymentMethodCreation(data) {
            self.cancel();
            if (!self.saveMethod) {
                SweetAlert.swal($translate.instant('ATTENTION'), $translate.instant('SUCCESS'), "success");
            } else {
                SweetAlert.swal($translate.instant('ATTENTION'), $translate.instant('SUCCESS'), "success");
            }
        }

        function handlePaymentMethodErr(err) {
            if (err.status === 408) {
                SweetAlert.swal($translate.instant('ATTENTION'), $translate.instant('SVAL_COMUNICATION_PAYU_ERROR'), "error");
            } else if (err.status === 409) {
                SweetAlert.swal($translate.instant('ATTENTION'), $translate.instant('TEXT_CARD_NUMBER_INVALID'), "error");
            } else if (err.status === 500) {
                if (err.data.serverError)
                    SweetAlert.swal($translate.instant('ATTENTION'), err.data.serverError.error, "error");
                else
                    SweetAlert.swal($translate.instant('ATTENTION'), $translate.instant('SERVER_ERROR'), "error");
            }

        }


        function handlePaymentSuccess(data) {

            if (!self.saveMethod) {
                SweetAlert.swal($translate.instant('ATTENTION'), $translate.instant('PAYMENT_APPROVED'), "success");
                self.cancel();
            } else {
                var method = buildNewPaymentMethod();
                Paymentsv2.newPaymentMethod(self.centralStation.idCentralStation, method)
                    .then(handlePaymentMethodCreation, handlePaymentMethodErr);
            }

        }

        function handlePaymentErr(err) {
            if (err.status === 408) {
                self.cancel();
                SweetAlert.swal($translate.instant('ATTENTION'), $translate.instant('PAYMENT_COMUNICATION_ERROR'), "error");
            } else if (err.status === 403) {
                self.cancel();
                SweetAlert.swal($translate.instant('ATTENTION'), $translate.instant('PAYMENT_DECLINED'), "error");
            }
        }

        function handleMethods(data) {
            data.data.push(
                {
                    _id: "1",
                    maskedNumber: $translate.instant('PAY_WITH_UNREGISTERED_CARD')

                }
            );
            self.methods = data.data;
            self.init = false;
        }


        self.fetchMethods =
            function () {
                Paymentsv2.getByCentralStation(self.centralStation.idCentralStation)
                    .then(handleMethods, handleErr);
            };


        function handleErr(err) {
            self.init = false;
            console.log(err);
        }

        self.cancel =
            function () {
                self.modalInstance.dismiss('cancel');
            };

        self.deleteMethod =
            function (method) {
                SweetAlert.swal({
                    title: $translate.instant('SVAL_DELETE_PAYMENT_TYPE'),
                    text: $translate.instant('PERFORM_OPERATION_QUESTION'),
                    type: "warning",
                    confirmButtonColor: "#DD6B55",
                    cancelButtonText: $translate.instant('CANCEL'),
                    showCancelButton: true,
                    closeOnConfirm: false,
                    showLoaderOnConfirm: true
                }, function (isConfirm) {
                    if (isConfirm) {
                        Paymentsv2.deletePaymentMethod(self.centralStation.idCentralStation, method)
                            .then(
                                function success(resp) {
                                    SweetAlert.swal({
                                        title: $translate.instant('ATTENTION'),
                                        text: $translate.instant('DELETED_PAYMENT_TYPE'),
                                        type: "success",
                                        closeOnConfirm: true
                                    }, function () {
                                        self.init = true;
                                        self.fetchMethods();
                                    });
                                },
                                function error(err) {
                                    if (err.status === 409 || err.status === 408) {
                                        SweetAlert.swal($translate.instant('ATTENTION'), $translate.instant('SVAL_COMUNICATION_PAYU_ERROR'), "error");
                                    }
                                    console.log(err)
                                }
                            );

                    }
                });


            };
    }

})();