angular.module('cloudxcmClientsApp')
    .controller('AccountsCtrlv2', AccountsCtrlv2);

AccountsCtrlv2.$inject = ['Accountsv2', 'Auth', 'lodash', '$uibModal', '$translate','$rootScope'];


function AccountsCtrlv2(Accountsv2, Auth, lodash, $uibModal, $translate,$rootScope) {
    var self = this;
    $rootScope.titulo="ACCOUNT_MANAGEMENT";
    delete $rootScope.subtitulo;
    self.centralStationsRdy=false;
    self.centralStations = [];
    self.currentCs = null;
    self.accounts = [];
    self.query = true;
    self.updateInProgress = false;
    self.noMoreDataAvaliable = false;
    self.resultByPage = 10;
    self.currentPage = 0;


    Auth.getCurrentUser()
        .then(
            handleUser,
            handleError
        );


    function handleUser(data) {
        self.centralStationsRdy=true;
        self.centralStations = lodash.map(data.centralStationClient.centralStations, 'centralStation');
    }


    function handleError(err) {
        self.centralStationsRdy=true;
        self.query = false;
        console.log(err);
    }


    self.refresh =
        function (cs) {
            self.currentCs = null;
            self.fetchAccounts(cs);

        };


    self.statusAlarm = [
        {
            name: 'OPEN',
            key: 'DISARMED_BY',
            icon: 'http://nevulametacentro.blob.core.windows.net/metacenter/N_Disarmed.png'
        },{
            name: 'CLOSE',
            key: 'ARMED_BY',
            icon: 'http://nevulametacentro.blob.core.windows.net/metacenter/N_Armed_Away.png'
        }
    ];

    self.enableMap=
        function(){
            self.showMap=true;
        };

    self.disableMap=
        function(){
            self.showMap=false;

        };

    self.transformAccount =
        function (account) {

            if (!account.cloudIcon) {
                account.cloudIcon = {
                    image: {
                        value: "https://nevulacsblobmetaqa.blob.core.windows.net/metacenter/undefined/8d270375-28f3-4b93-bda8-86255d190583",
                        svgPath: 'M 32.5614 243 L 103.2982 243 L 103.2982 159.9756 L 152.7018 159.9756 L 152.7018 243 L 228.4912 243 L 227.9298 117.4312 L 256 137.0878 L 256 113.5156 L 227.9298 93.859 L 227.9298 13 L 196.4912 13 L 196.4912 71.8463 L 128 23.8829 L 0 113.5156 L 0 137.0878 L 32.5614 114.2841 L 32.5614 243 Z',

                    },
                    color: {
                        value: '#000000'
                    }
                };
            }


            if (!account.lastStatus) {
                account.lastStatus = {status:'OPEN',date: self.calcDate(moment())}
            } else {
                account.lastStatus.date = self.calcDate(account.lastStatus.datetimeAccount);
            }


            account.iconStatus=lodash.result(lodash.find(self.statusAlarm, {name: account.lastStatus.status}),'icon');


            var location = {
                latitude: account.location.coordinates.latitude,
                longitude: account.location.coordinates.longitude,
                icon: account.cloudIcon.image.svgPath,
                color: account.cloudIcon.color.value,
                iconAditional:account.iconStatus,
                information: account.location.address + ' - (' + account.location.zipCode + ')'
            };




            self.positions.push(location);
            /**
             var horaAccount = moment.utc(account.createdAtLocal);
             var classificationIcon = "";
             var icon = "";


             if (!account.account.cloudIcon) {
                account.account.cloudIcon =
                    {
                        image: {
                            value: "https://nevulacsblobmetaqa.blob.core.windows.net/metacenter/undefined/8d270375-28f3-4b93-bda8-86255d190583",
                            svgPath: 'M 32.5614 243 L 103.2982 243 L 103.2982 159.9756 L 152.7018 159.9756 L 152.7018 243 L 228.4912 243 L 227.9298 117.4312 L 256 137.0878 L 256 113.5156 L 227.9298 93.859 L 227.9298 13 L 196.4912 13 L 196.4912 71.8463 L 128 23.8829 L 0 113.5156 L 0 137.0878 L 32.5614 114.2841 L 32.5614 243 Z',

                        },
                        color: {
                            value: '#000000'
                        }
                    }
            }

             if (account.origin) {
                account.origin = $translate.instant('CREATED_BY') + $translate.instant(account.origin);
            }

             if (account.status === 'SENT') {
                account.iconStatus = 'icon icon-ico-cloud-solicitud';
            } else if (account.status === 'ASSIGNED' || account.status === 'OPENED') {
                account.iconStatus = 'icon icon-ico-cloud-ticket-planificado';
            } else if (account.status === 'CANCELED') {
                account.iconStatus = 'icon icon-ico-cloud-solicitud-cancelada';
            } else if (account.status === 'FINISHED' || account.status === 'CLOSED') {
                account.iconStatus = 'icon icon-ico-cloud-ticket-finalizado';
            }


             account.createdAt = self.calcDate(account.createdAt) + ' ' + moment.utc(account.createdAt).format("HH:mm:ss");*/




            console.log(account);
            self.accounts.push(account);
        };

    self.calcDate =
        function (date) {

            var currDate = moment(date);
            var today = moment(new Date);
            var yesterday = moment(today.subtract(1, 'day'));
            var prefix = '';

            currDate = currDate.format("DD-MM-YYYY");
            today = today.format("DD-MM-YYYY");
            yesterday = yesterday.format("DD-MM-YYYY");

            if (currDate === today) {
                prefix = $translate.instant('TODAY');

            }
            else if (currDate === yesterday) {
                prefix = $translate.instant('YESTERDAY');

            }
            else {
                prefix = currDate;
            }
            return prefix;
        };

    function handleAccounts(data) {
        if (data.data.length === 0)
            self.noMoreDataAvaliable = true;
        
        lodash.forEach(data.data, self.transformAccount);
        self.currentPage++;
        self.query = false;
        self.updateInProgress = false;
        


    }


    self.fetchAccounts =
        function (cs) {
            if (cs) {
                if (self.currentCs !== cs) {
                    self.currentPage = 0;
                    self.currentCs = cs;
                    self.accounts = [];
                    self.positions = [];
                    self.query = true;
                    self.updateInProgress = false;
                    self.noMoreDataAvaliable = false;
                }

                if (self.updateInProgress)
                    return;
                else
                    self.updateInProgress = true;


                Accountsv2
                    .getByCentralStation(cs.idCentralStation, self.resultByPage * self.currentPage, self.resultByPage)
                    .then(handleAccounts, handleError);
            }
        };


    self.openAccountModal =
        function (centralStation, id) {
            var modalInstance = $uibModal.open({
                animation: true,
                component: 'account',
                size: 'md',
                resolve: {
                    centralStation: function () {
                        return centralStation
                    },
                    idAccount: function () {
                        return id;
                    }
                }
            });
            modalInstance.result.then(function (item) {
                if (action === 'accounts' && item === 'new')
                    $route.reload();
            }, function () {
            });
        };

    self.openCloudIconModal =
        function (centralStation, id) {
            var modalInstance = $uibModal.open({
                animation: true,
                component: 'cloud-icon',
                size: 'md',
                resolve: {
                    centralStation: function () {
                        return centralStation
                    },
                    idAccount: function () {
                        return id;
                    }
                }
            });

            modalInstance.result.then(function (item) {
                if (action === 'accounts' && item === 'new')
                    $route.reload();
            }, function () {
            });
        };


}

