'use strict';

var requests = {
    bindings: {
        user:'<'
    },
    templateUrl: 'app/support2/requests/requests.html',
    controller: 'RequestsCtrlv2 as vm'
};

angular
    .module('cloudxcmClientsApp')
    .component('requests', requests);