(function(){
    'use strict';

    var paymentMethods = {
        bindings: {
            resolve:'<',
            modalInstance:'<'
        },
        templateUrl: 'app/payment2/modal/payment-methods/payment-methods.html',
        controller: 'PaymentMethodsCtrlv2 as vm'
    };

    angular
        .module('cloudxcmClientsApp')
        .component('paymentMethods', paymentMethods);

})();