(function(){
    'use strict';

    var cloudIcon = {
        bindings: {
            resolve:'<',
            modalInstance:'<'
        },
        templateUrl: 'app/accounts2/modal/cloud-icon/cloud-icon.html',
        controller: 'CloudIconCtrlv2 as vm'
    };

    angular
        .module('cloudxcmClientsApp')
        .component('cloudIcon', cloudIcon);

})();