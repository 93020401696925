'use strict';

angular.module('cloudxcmClientsApp')
  .directive('uiButton', [
      '$translate','$location','$timeout',
      function ($translate,$location,$timeout) {
    return {
      templateUrl: 'components/directives/ui-button/ui-button.html',
      restrict: 'EA',
      replace:true,
      scope: {
        type:"@",
        action:"@",
        state:"@",
        disabled:"=",
        text:"@",
        mini:"@",
        supermini:"@",
        actionclick:"&",
        overtext: "@",
        style: "@",
        goto:"@",
        eventhandler: '&ngClick',
        entity:"@",
        status:"@"
      },
      link: function ($scope, element, attrs) {

        $timeout(function() {

        $scope.cssButton="fa  btn-default";
        $scope.iconButton="";
        if($scope.supermini){$scope.superminiCss=" supermini "};

        $scope.isDisabled=$scope.disabled;

        $scope.execute = function() {

          switch($scope.action) {
            case "back":
              $scope.cssButton="btn  btn-back";  $scope.iconButton="icon icon-ico-anterior";
              $scope.defaultText=$translate.instant('BACK');
              break;
            case "forward":
              $scope.cssButton="btn  btn-forward";  $scope.iconButton="icon icon-ico-siguiente";
              $scope.defaultText=$translate.instant('FORWARD');
              break;
            case "next":
              $scope.cssButton="btn  btn-next";  $scope.iconButton="icon icon-ico-siguiente";
              $scope.defaultText=$translate.instant('NEXT');
              break;
            case "up":
              $scope.cssButton="btn  btn-default";  $scope.iconButton="fa fa-sort-asc";
              $scope.defaultText=$translate.instant('UP');
              break;
            case "down":
              $scope.cssButton="btn  btn-default";  $scope.iconButton="fa fa-sort-desc";
              $scope.defaultText=$translate.instant('DOWN');
              break;
            case "help":
              $scope.cssButton="btn  btn-help";  $scope.iconButton="icon icon-ico-sec-ayuda";
              $scope.defaultText="";
              //$scope.isDisabled=false;
              //console.log("ENTRO EN HELP", element);
              //element[0].prop('disabled', false);
              break;
            case "new":
              $scope.cssButton="btn  btn-new";  $scope.iconButton="icon icon-ico-nuevo";
              $scope.defaultText=$translate.instant('NEW');
              break;
            case "add":
              $scope.cssButton="btn  btn-new";  $scope.iconButton="icon icon-ico-agregar";
              $scope.defaultText=$translate.instant('ADD');
              break;
            case "save":
              $scope.cssButton="btn  btn-save";  $scope.iconButton="icon icon-ico-guardar";
              $scope.defaultText=$translate.instant('SAVE');
              break;
            case "ok":
              $scope.cssButton="btn  btn-save";
              $scope.defaultText=$translate.instant('CANCEL');
              break;
            case "update":
              $scope.cssButton="btn  btn-update";  $scope.iconButton="icon icon-ico-actualizar";
              $scope.defaultText=$translate.instant('UPDATE');
              break;
            case "search":
              $scope.cssButton="btn  btn-search";  $scope.iconButton="icon icon-ico-buscar";
              $scope.defaultText=$translate.instant('SEARCH');
              break;
            case "verify":
              $scope.cssButton="btn  btn-search";  $scope.iconButton="icon icon-ico-verificar-campo";
              $scope.defaultText=$translate.instant('VERIFY');
              break;
            case "detect":
              $scope.cssButton="btn  btn-search";  $scope.iconButton="icon icon-ico-buscar";
              $scope.defaultText=$translate.instant('PAUSE');
              break;
            case "edit":
              $scope.cssButton="btn  btn-edit";  $scope.iconButton="glyphicon glyphicon-edit";
              $scope.defaultText=$translate.instant('EDIT');
              break;
            case "edit-dashboard":
              $scope.cssButton="btn  btn-default";  $scope.iconButton="icon icon-ico-editar";
              $scope.defaultText=$translate.instant('EDIT');
              break;
            case "pick":
              $scope.cssButton="btn  btn-edit";  $scope.iconButton="icon icon-ico-seleccionar";
              $scope.defaultText=$translate.instant('SELECT');
              break;
            case "pick-date":
              $scope.cssButton="btn  btn-edit";  $scope.iconButton="fa fa-calendar";
              $scope.defaultText=$translate.instant('PICK_DATE');
              break;
            case "pick-time":
              $scope.cssButton="btn  btn-edit";  $scope.iconButton="fa fa-clock-o";
              $scope.defaultText=$translate.instant('PICK_TIME');
              break;
            case "pick-color":
              $scope.cssButton="btn btn-pickcolor";  $scope.iconButton="icon icon-without-icon";
              $scope.defaultText=$translate.instant('PICK_COLOR');
              break;
            case "filter":
              $scope.cssButton="btn  btn-edit";  $scope.iconButton="icon icon-ico-filtrar";
              $scope.defaultText=$translate.instant('FILTER');
              break;
            case "details":
              $scope.cssButton="btn  btn-edit";  $scope.iconButton="fa fa-eye";
              $scope.defaultText=$translate.instant('DETAILS');
              break;
            case "attend":
              $scope.cssButton="btn  btn-edit";  $scope.iconButton="icon icon-atender";
              $scope.defaultText=$translate.instant('ATTEND');
              break;
            case "details-technician":
              $scope.cssButton="btn  btn-edit";  $scope.iconButton="icon icon-ico-tecnicos";
              $scope.defaultText=$translate.instant('DETAILS');
              break;
            case "delete":
              $scope.cssButton="btn btn-erase";  $scope.iconButton="icon icon-ico-cloud-borrar";
              $scope.defaultText=$translate.instant('DELETE');
              break;
            case "erase":
              $scope.cssButton="btn  btn-erase";  $scope.iconButton="icon icon-ico-borrar";
              $scope.defaultText=$translate.instant('DELETE');
              break;
            case "remove":
              $scope.cssButton="btn  btn-erase";  $scope.iconButton="icon icon-ico-remover";
              $scope.defaultText=$translate.instant('DELETE');
              break;
            case "cancel":
              $scope.cssButton="btn  btn-cancel";  $scope.iconButton="icon icon-ico-cancelar";
              $scope.defaultText=$translate.instant('CANCEL');
              break;
            case "reset":
              $scope.cssButton="btn  btn-reset";  $scope.iconButton="icon icon-ico-limpiar";
              $scope.defaultText=$translate.instant('RESET');
              break;
            case "clean":
              $scope.cssButton="btn  btn-reset";  $scope.iconButton="icon icon-ico-limpiar";
              $scope.defaultText=$translate.instant('CLEAN');
              break;
            case "default":
              $scope.cssButton="btn  btn-default";  $scope.iconButton="";
              break;
            case "dropdown":
              $scope.cssButton="btn  btn-dropdown";  $scope.iconButton="fa  fa-sort-desc";
              $scope.defaultText="";
              break;
            case "conf":
              $scope.cssButton="btn  btn-conf";  $scope.iconButton="icon icon-ico-configurar";
              $scope.defaultText=$translate.instant('CONFIGURATION');
              break;
            case "user-details":
              $scope.cssButton="btn  btn-edit";  $scope.iconButton="icon icon-ico-detalle";
              $scope.defaultText=$translate.instant('USERS');
              break;
            case "checked":
              $scope.cssButton="btn  btn-success";  $scope.iconButton="fa fa-check";
              $scope.defaultText=$translate.instant('READY');
              break;
            case "done":
              $scope.cssButton="btn btn-success";  $scope.iconButton="icon icon-ico-cloud-ticket-finalizado";
              $scope.defaultText=$translate.instant('DONE');
              break;
            case "pause":
              $scope.cssButton="btn btn-edit";  $scope.iconButton="icon icon-pausar";
              $scope.defaultText=$translate.instant('PAUSE');
              break;
            case "permissions":
              $scope.cssButton="btn btn-warning";  $scope.iconButton="icon icon-ico-permiso-acceso";
              $scope.defaultText=$translate.instant('SET_PERMISSIONS');
              break;
            case "like":
              $scope.cssButton="btn btn-default";  $scope.iconButton="fa fa-thumbs-o-up";
              $scope.defaultText=$translate.instant('LIKE');
              break;
            case "dislike":
              $scope.cssButton="btn btn-default";  $scope.iconButton="fa fa-thumbs-o-down";
              $scope.defaultText=$translate.instant('DISLIKE');
              break;
            case "key":
              $scope.cssButton="btn btn-search";  $scope.iconButton="fa fa-key";
              $scope.defaultText=$translate.instant('UNIQUE');
              break;
            case "print-pdf":
              $scope.cssButton="btn btn-cancel";  $scope.iconButton="icon icon-ico-exportar-pdf";
              $scope.defaultText=$translate.instant('PRINT_PDF');
              break;
            case "print-excel":
              $scope.cssButton="btn btn-success";  $scope.iconButton="icon icon-ico-exportar-excel";
              $scope.defaultText=$translate.instant('PRINT_EXCEL');
              break;
           case "generate-auto-report":
              $scope.cssButton="btn btn-edit";  $scope.iconButton="icon icon-ico-reportes-automaticos";
              $scope.defaultText=$translate.instant('GENERATE_REPORT');
              break;
            case "play":
              $scope.cssButton="btn btn-default";  $scope.iconButton="icon icon-ico-siguiente";
              $scope.defaultText=$translate.instant('PLAY');
              break;
            case "refresh":
              $scope.cssButton="btn btn-default";  $scope.iconButton="fa fa-refresh";
              $scope.defaultText=$translate.instant('REFRESH');
              break;
            case "stop":
              $scope.cssButton="btn btn-edit";  $scope.iconButton="fa fa-stop";
              $scope.defaultText=$translate.instant('STOP');
              break;
            case "request-add":
              $scope.cssButton="btn  btn-new";  $scope.iconButton="icon icon-ico-cloud-nueva-solicitud";
              $scope.defaultText=$translate.instant('NEW');
              break;
            case "ticket-add":
              $scope.cssButton="btn  btn-new";  $scope.iconButton="icon icon-ico-gestion-tickets";
              $scope.defaultText=$translate.instant('STOP');
              break;
            case "payment-method":
              $scope.cssButton="btn  btn-new";  $scope.iconButton="fa fa-credit-card";
              break;
            case "pay":
              $scope.cssButton="btn  btn-new";  $scope.iconButton="fa fa-credit-card-alt";
              break;
            case "ticket-accept":
              $scope.cssButton="btn  btn-new";  $scope.iconButton="icon icon-ico-gestion-tickets";
              $scope.defaultText=$translate.instant('STOP');
              break;
            case "ticket-paused":
              $scope.cssButton="btn  btn-new";  $scope.iconButton="icon icon-ico-gestion-tickets";
              $scope.defaultText=$translate.instant('STOP');
              break;

            case "log":
              $scope.cssButton="btn  btn-edit";  $scope.iconButton="fa fa-history";
              $scope.defaultText=$translate.instant('LOG');
              break;

            case "note":
              $scope.cssButton="btn  btn-edit";  $scope.iconButton="fa fa-sticky-note";
              $scope.defaultText=$translate.instant('STOP');
              break;

            case "account":
              $scope.cssButton="btn btn-warning";  $scope.iconButton="icon icon-ico-sec-cuentas";
              $scope.defaultText=$translate.instant('STOP');
              break;
            case "rotate-right":
              $scope.cssButton="btn btn-warning";  $scope.iconButton="icon icon-ico-rotar-derecha";
              $scope.defaultText=$translate.instant('ROTATE_RIGHT');
              break;
            case "rotate-left":
              $scope.cssButton="btn btn-warning";  $scope.iconButton="icon icon-ico-rotar-izquierda";
              $scope.defaultText=$translate.instant('ROTATE_LEFT');
              break;
            case "flip-h":
              $scope.cssButton="btn btn-warning";  $scope.iconButton="icon icon-ico-voltear-horizontal";
              $scope.defaultText=$translate.instant('FLIP_HORIZONTAL');
              break;
            case "flip-v":
              $scope.cssButton="btn btn-warning";  $scope.iconButton="icon icon-ico-voltear-vertical";
              $scope.defaultText=$translate.instant('FLIP_VERTICAL');
              break;
            case "adjust-display":
              $scope.cssButton="btn btn-warning";  $scope.iconButton="icon icon-ico-visible-h-d";
              $scope.defaultText=$translate.instant('ADJUST');
              break;
            case "connect":
              $scope.cssButton="btn  btn-save";  $scope.iconButton="icon icon-ico-guardar";
              $scope.defaultText=$translate.instant('SAVE');
              break;
            case "disconnect":
              $scope.cssButton="btn  btn-cancel";  $scope.iconButton="icon icon-ico-cancelar";
              $scope.defaultText=$translate.instant('CANCEL');
              break;

          }

          if(angular.isDefined($scope.text)) {
            $scope.defaultText=$scope.text;
          }

          if(angular.isDefined($scope.goto)){
            $scope.actionclick=function() {
              $scope.loading=true;
              $scope.isDisabled=true;
              $location.path($scope.goto)
            }
          }

          if(angular.isDefined($scope.status) && $scope.status=="loading") {
            $scope.defaultText="";
            $scope.iconButton="fa fa-spinner fa-pulse";
          }

          if(angular.isDefined($scope.status) && $scope.status=="checked") {
            //$scope.defaultText="";
            $scope.cssButton="btn btn-save";  $scope.iconButton="fa fa-check";
          }
          if(angular.isDefined($scope.entity)) {
            switch($scope.entity) {
              case "ticket":
                $scope.iconButton="icon icon-ico-cloud-ticket";
                break;
              case "modem":
                $scope.iconButton="icon icon-ico-ticket-soporte-tecnico-buscar-modem-sms";
                break;
            }
          }
        };

        $scope.execute();

        $scope.setLoading = function() {
          $scope.loading=true;
          $scope.cssAnimate="is-loading";
        };

        $scope.unSetLoading = function() {
          $scope.loading=false;
          $scope.cssAnimate="";
          ($scope.disabled) ? $scope.isDisabled=false : $scope.isDisabled=$scope.disabled;
        };

        $scope.$watch('status', function(newValue, oldValue) {
          if (newValue !== oldValue) {
            if($scope.status==1 ||  $scope.status=="loading" ){
              $scope.isDisabled=true;
            }
            else {
              $scope.unSetLoading();
            }
            $scope.execute();
          }
        }, true);

        $scope.$watch('disabled', function(newValue, oldValue) {

          if (newValue !== oldValue) {
            $scope.isDisabled=$scope.disabled;
            $scope.execute();
          }
        }, true);

        $scope.actionClickProcess = function () {
          if ($scope.status) $scope.setLoading();
          $scope.actionclick();
        };

        },10);

      }
    };
  }]);
