(function(){
    'use strict';
    angular.module('cloudxcmClientsApp')
        .controller('userNotRegistered',userNotRegistered);

    userNotRegistered.$inject=['Auth'];

    function userNotRegistered(Auth){

        var self =this;
        self.logOut=Auth.logout;
    }
})();
