'use strict';

angular.module('cloudxcmClientsApp')
    .factory('Requestsv2', Requestsv2);

Requestsv2.$inject = ['$http', '$cookies', 'Upload'];


function Requestsv2($http, $cookies, Upload) {
    var self = this;

    self.response = {
        getByCentralStation: getByCentralStation,
        getDetail: getDetail,
        create: create,
        Delete:Delete
    };

    return self.response;

    function getByCentralStation(cs, skip, limit) {
        var idClient = $cookies.get('idClient');
        var url = "/api2/central-station-clients/" + idClient + "/requests?filters[idCentralStation]=" + cs + "&orderBy=-createdAt&populate=idAccount"
            + "&include=idCouch"
            + "&include=createdAtLocal"
            + "&include=origin"
            + "&include=status"
            + "&include=createdAt"
            + "&include=idCentralStation"
            + "&include=code"
            + "&include=incidence"
            + "&include=account.cloudIcon.image.svgPath"
            + "&include=account.cloudIcon.color.value"
            + "&include=account.name"
            + "&skip=" + skip + "&limit=" + limit;
        return $http.get(url);
    }

    function getDetail(cs, ticket) {
        var idClient = $cookies.get('idClient');
        var url = '/api2/central-station-clients/' + idClient + '/central-stations/' + cs + '/requests/' + ticket +
            "/?populate=idAccount&populate=idCentralStation"
            + "&include=idCouch"
            + "&include=observation"
            + "&include=cloudImage"
            + "&include=image"
            + "&include=preferredSchedule"
            + "&include=centralStation"
            + "&include=origin"
            + "&include=incidence"
            + "&include=createdAt"
            + "&include=idCentralStation"
            + "&include=account.cloudIcon.image.svgPath"
            + "&include=account.cloudIcon.image.svgPath"
            + "&include=account.name"
        ;
        return $http.get(url);
    }

    function create(requestBody) {
        var url = '/api2/central-stations/' + requestBody.request.idCentralStation + '/requests';
        return Upload.upload({
            url: url,
            method: 'POST',
            fields: requestBody.request,
            file: requestBody.img
        });
    }

    function Delete(cs, request) {
        var idClient = $cookies.get('idClient');
        var url = '/api2/central-stations/'+cs+'/requests/'+request;
        return $http.delete(url);

    }


}