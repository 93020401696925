'use strict';

angular.module('cloudxcmClientsApp', [
    'ngResource',
    'ngSanitize',
    'ngRoute',
    'ngCookies',
    'btford.socket-io',
    'ui.bootstrap',
    'uiSwitch',
    'angular-ladda',
    'ngAnimate',
    'pascalprecht.translate',
    'ng-breadcrumbs',
    'ngLodash',
    'uiGmapgoogle-maps',
    'angularMoment',
    'datatables',
    'ngFileUpload',
    'ui.tab.scroll',

    'datatables.bootstrap',
    'ui.bootstrap.showErrors',
    'oitozero.ngSweetAlert',
    'ui.select',
    'vds.multirange',
    'infinite-scroll',
    'ngStorage',
    'templates'
])
    .config(['$routeProvider', '$locationProvider', '$translateProvider', '$httpProvider', 'uiGmapGoogleMapApiProvider',
        function ($routeProvider, $locationProvider, $translateProvider, $httpProvider, uiGmapGoogleMapApiProvider) {
            $routeProvider.when('/en-construccion', {
                templateUrl: 'app/en-construccion.html',
                label: 'En construccion'
            })
                .otherwise({
                    redirectTo: '/'
                });

            $locationProvider.html5Mode(true);
            $httpProvider.interceptors.push('authInterceptor');

            $translateProvider.useStaticFilesLoader({
                prefix: '/languages/',
                suffix: '.json'
            });


            $translateProvider.preferredLanguage('es');
            moment.locale('es');

            // Enable escaping of HTML
            $translateProvider.useSanitizeValueStrategy('escaped');
            uiGmapGoogleMapApiProvider.configure({
                key: 'AIzaSyAJkAfj6gjohuvd_OVYMUxRfm6hsU_ObTI',
                v: '3.20', //defaults to latest 3.X anyhow
                libraries: 'weather,geometry,visualization'
            });
        }])

    .factory('authInterceptor',
        ['$rootScope', '$q', '$cookies', '$location',
            function ($rootScope, $q, $cookies, $location) {
                return {
                    // Add authorization token to headers
                    request: function (config) {
                        config.headers = config.headers || {};
                        if (config.url.indexOf('auth') >= 0 || config.url.indexOf('api') >= 0) {
                            if (config.url.indexOf('api2') >= 0) {
                                config.url = "https://api.nevula.com/api/" + config.url.split('/api2/')[1];
                            }
                            else
                                config.url = "https://api2.nevula.com" + (config.url[0] == "/" ? "" : "/") + config.url;
                        }

                        if ($cookies.get('token')) {
                            var token = $cookies.get('token');
                            config.headers.Authorization = 'Bearer ' + token.split('"')[1];
                        }
                        return config;
                    },

                    // Intercept 401s and redirect you to login
                    responseError: function (response) {
                        if (response.status === 401) {
                            $cookies.remove('token');
                            $location.path('/login');
                            return $q.reject(response);
                        }
                        else {
                            return $q.reject(response);
                        }


                        if (response.status === 500 && (response.data === "Unauthorized" || response.statusText === "Unauthorized")) {
                            /*$cookieStore.remove('token');
                            $cookieStore.remove('refreshToken');
                            $cookieStore.remove('idClient');
                            $rootScope.logout = true;
                            $rootScope.isLoggedIn = false;
                            $localStorage.currentUser=undefined;
                            window.location = 'https://login.microsoftonline.com/nevuladir.onmicrosoft.com/oauth2/v2.0/logout?p=B2C_1_SIGN_UP_OR_SIGN_IN&post_logout_redirect_uri=https://clients.nevula.com';*/
                            return $q.reject(response);
                        }

                    }
                };
            }])


    .controller('LayoutCtrl', ['$scope', '$http', 'Auth', '$rootScope', 'breadcrumbs', '$translate', 'DTDefaultOptions', '$route', '$cookieStore', '$location',
        function ($scope, $http, Auth, $rootScope, breadcrumbs, $translate, DTDefaultOptions, $route, $cookieStore, $location) {
            $scope.breadcrumbs = breadcrumbs;




            if (!$cookieStore.get('token')) {
                $rootScope.isLoggedIn = false;
                $location.path('/login');

            }
            else {

                $rootScope.isLoggedIn = true;
            }

            $scope.$on('scanner-started', function (event, args) {
                $scope.isLoggedIn = args.isLoggedIn;
                if ($location.path() === '/')
                    $location.path('/login');
            });

            $scope.changeLanguage = function (langKey) {
                $translate.use(langKey);
                moment.locale(langKey);
                DTDefaultOptions.setLanguageSource('languages/datatables_' + langKey + '.json');
                $route.reload();
            };
        }])
    .run(['DTDefaultOptions', function (DTDefaultOptions) {
        DTDefaultOptions.setLoadingTemplate(' <div class="loader2"><svg class="circular2"><circle class="path" cx="50" cy="50" r="27" fill="none" stroke-width="2" stroke-miterlimit="10"/></svg></div>');
        DTDefaultOptions.setLanguageSource('languages/datatables_es.json');
    }])
    .run([
        '$rootScope', '$location', 'Auth', '$cookieStore',
        function ($rootScope, $location, Auth, $cookieStore) {
            // Redirect to login if route requires auth and you're not logged in
            $rootScope.$on('$routeChangeStart', function (event, next) {

                if ($location.path() === "/login" || $location.path() === "/" || $location.path() === "/pages/not_registered") {

                    $rootScope.$broadcast('scanner-started', {isLoggedIn: false});
                } else {
                    $rootScope.$broadcast('scanner-started', {isLoggedIn: true});
                }

                if ($location.path() === "/login") {
                    if (!$cookieStore.get('token')) {
                        $location.path('/login');
                    }
                }


            });
        }]);

