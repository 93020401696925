(function(){
    'use strict';

    var ticketLog = {
        bindings: {
            resolve:'<',
            modalInstance:'<'
        },
        templateUrl: 'app/support2/tickets/modal/log/ticket.log.html',
        controller: 'TicketLogCtrlv2 as vm'
    };

    angular
        .module('cloudxcmClientsApp')
        .component('ticketLog', ticketLog);

})();